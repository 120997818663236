import React, { useEffect, useState, useRef, useContext } from "react";
import * as echarts from "echarts";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../..";

const CommunityKitchenTrendView = (props) => {
  const echartsDom2 = useRef(null);
  const { theme } = useContext(ThemeContext);
  const [chart, setChart] = useState(null);
  const { t } = useTranslation();

  // Aggregating "Kids" data based on provided keys
  const kidsTotal = Object.entries(props.dataval || {}).reduce((acc, [key, value]) => {
    if (key.includes("Children Male") || key.includes("Children Female")) {
      return acc + value;
    }
    return acc;
  }, 0);

  // Defining values for each category
  const menTotal = props.dataval?.Males || 0;
  const womenTotal = props.dataval?.Females || 0;
  const transgenderTotal = props.dataval?.Transgender || 0;

  const getLegendColor = (theme) => (theme === "light" ? "#2B6591" : "#fff");

  const options = {
    tooltip: {
      trigger: "item",
      confine: true,
    },
    legend: {
      right: "0%",
      bottom: "0",
      selectedMode: false,
      textStyle: {
        color: getLegendColor(theme),
      },
    },
    xAxis: {
      type: "category",
      data: ["Men", "Women", "Kids", "Transgender"],
      axisLine: { lineStyle: { color: getLegendColor(theme) } },
      axisTick: { show: false },
      axisLabel: {
        interval: 0, // Show all labels
        rotate: 0, // Avoid rotation for readability
        padding: [1, 0, 0, 0], // Add padding below labels
        color: getLegendColor(theme),
      },
      splitLine: { show: false },
    },
    yAxis: {
      type: "value",
      axisLine: { lineStyle: { color: getLegendColor(theme) } },
      splitLine: { show: false },
      min: 0,
    },
    series: [
      {
        name: "Men",
        data: [menTotal, 0, 0, 0],
        type: "bar",
        itemStyle: { color: "#519EFE", barBorderRadius: [10, 10, 0, 0] },
        barWidth: "15", // Smaller width to reduce gaps
        barGap: "-100%",
      },
      {
        name: "Women",
        data: [0, womenTotal, 0, 0],
        type: "bar",
        itemStyle: { color: "#B981F6", barBorderRadius: [10, 10, 0, 0] },
        barWidth: "15",
        barGap: "-100%",
      },
      {
        name: "Kids",
        data: [0, 0, kidsTotal, 0],
        type: "bar",
        itemStyle: { color: "#E28771", barBorderRadius: [10, 10, 0, 0] },
        barWidth: "15",
        barGap: "-100%",
      },
      {
        name: "Transgender",
        data: [0, 0, 0, transgenderTotal],
        type: "bar",
        itemStyle: { color: "#D252AA", barBorderRadius: [10, 10, 0, 0] },
        barWidth: "15",
        barGap: "-100%",
      },
    ],
    grid: {
      left: "12%",
      bottom: "25%",
      top: "5%",
      right: "5%",
    },
    barCategoryGap: "20%",
  };

  useEffect(() => {
    const myChart = echarts.init(echartsDom2.current);
    myChart.setOption(options);

    const handleResize = () => {
      myChart.resize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      myChart.dispose();
    };
  }, [props.dataval, t, theme]);

  return (
    <div className="card" style={{ width: "100%", height: "95%" }}>
      <div className="card-body" style={{ height: "100%" }}>
        <div className="card-title">
          {t("navlinklabels.communitykitchen")}
        </div>
        <div ref={echartsDom2} style={{ height: "100%", width: "100%" }} />
      </div>
    </div>
  );
};

export default CommunityKitchenTrendView;
