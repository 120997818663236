import React, { useEffect, useState } from "react";
import "./DashboardCard.css";

export default function DashboardCard({
  data,
  config,
  clickedKey,
  setClickedKey,
  triggeredFunction,
}) {
  const colors = ["#519EFE", "#b981f6", "#E28771", "#D252AA"];
  const [cardData, setCardData] = useState({});
  useEffect(() => {
    setCardData(data);
  }, [data]);

  // Function to split the value into two parts: main value and the Rs part
  const formatCardData = (value) => {
    if (typeof value === "string") {
      const splitValue = value.split("(");
      return (
        <>
          {splitValue[0]} <br />({splitValue[1]}
        </>
      );
    }
    return value;
  };

  return (
    <div className="dashboard_card">
      <div>{config.title}</div>
      <div className="d-grid gap-2 mt-1">
        {config?.data.map((conf, index) => (
          <div
            key={index}
            className={`ds-single_card cursor-pointer ${
              clickedKey === conf.key ? "highlighted" : ""
            }`}
            onClick={() => {
              setClickedKey(conf?.key);
              triggeredFunction(
                conf?.label,
                conf?.apiKey,
                conf?.key,
                conf?.apiKey2,
                conf?.keyName
              );
            }}
            style={{ backgroundColor: colors[index % colors.length] }}
          >
            <div className="card-label">{conf.label}</div>
            <div className="card-data">
              {cardData && cardData[conf?.key]
                ? formatCardData(cardData[conf?.key])
                : 0}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
