export const dashboardCardConfig = [
  {
    id: 0,
    title: "Affected Population Report",
    data: [
      {
        label: "Population Affected",
        key: "population_affected",
        apiKey: "getPopAffectedDetails",
        apiKey2: "trnd_population_affected",
      },
      {
        label: "Population Evacuated",
        key: "population_evacuated",
        apiKey: "getPopEvacuationDetails",
        apiKey2: "trnd_population_evacuation",
      },
      {
        label: "Families Affected",
        key: "family_affected",
        apiKey: "getFamilyAffectedDetails",
        apiKey2: "trnd_family_affected",
      },
      {
        label: "Animals Affected",
        key: "animals_affected",
        apiKey: "getAnimalAffectedDetails",
        apiKey2: "trnd_animal_affected",
      },
    ],
  },
  {
    id: 0,
    title: "Loss and Damage Report",
    data: [
      {
        label: "Human Loss",
        key: "humans_loss",
        apiKey: "getHumanLossDetails",
        apiKey2: "trnd_human_loss",
      },
      {
        label: "Animal Loss",
        key: "animals_loss",
        apiKey: "getAnimalLossDetails",
        apiKey2: "trnd_animal_loss",
      },
    ],
  },
  {
    id: 0,
    title: "Boats Report",
    data: [
      {
        label: "Motor Boats",
        key: "motor_boat",
        apiKey: "getBoatsDetails",
        apiKey2: "trnd_boats_details",
        keyName: "motor_boat",
      },
      {
        label: "Private Boats",
        key: "private_boat",
        apiKey: "getBoatsDetails",
        apiKey2: "trnd_boats_details",
        keyName: "private_boat",
      },
      {
        label: "Govt Boats",
        key: "government_boat",
        apiKey: "getBoatsDetails",
        apiKey2: "trnd_boats_details",
        keyName: "government_boat",
      },
    ],
  },
  {
    id: 0,
    title: "Relief Report",
    data: [
      {
        label: "GR Family",
        key: "families_gr_distributed",
        apiKey: "getReliefDetails",
        apiKey2: "trnd_families_gr_distributed",
      },
      // {
      //   label: "GR Ammount",
      //   key: "gr_ammount",
      //   apiKey: "getReliefDetails",
      //   apiKey2: "trnd_relief_details",
      //   keyName: "families_gr_distributed",
      // },
      {
        label: "Dry Ration Packets",
        key: "dry_ration",
        apiKey: "getReliefDetails",
        apiKey2: "trnd_dry_ration",
      },
      {
        label: "Polythene Sheets",
        key: "polythene_sheets",
        apiKey: "getReliefDetails",
        apiKey2: "trnd_polythene_sheets",
      },
    ],
  },
  {
    id: 0,
    title: "Facilities Report",
    data: [
      {
        label: "Health Centre",
        key: "health_centre",
        apiKey: "getHealthCentreDetails",
        apiKey2: "trnd_health_center",
      },
      {
        label: "Community Kitchen",
        key: "community_kitchen",
        apiKey: "getCommunityKitchenDetails",
        apiKey2: "trnd_community_kitchen",
      },
      {
        label: "Flood Relief Camp",
        key: "people_remaining_in_camps",
        apiKey: "getFloodReliefDetails",
        apiKey2: "trnd_flood_camp",
      },
      {
        label: "Animal Shelter",
        key: "animal_shelter",
        apiKey: "getAnimalShelter",
        apiKey2: "trnd_animal_flood",
      },
    ],
  },
];

export const currentCardConfig = {
  title: "Facilities Report",
  data: [
    {
      label: "Affected Blocks",
      key: "curr_dss_affected_blocks",
      apiKey: "curr_fs_blocks_affected",
      apiKey2: "",
    },
    {
      label: "Affected Panchayats",
      key: "curr_dss_affected_gp",
      apiKey: "curr_fs_affected_panchayats",
      apiKey2: "",
    },
    {
      label: "Affected Municipal Bodies",
      key: "curr_dss_affected_mb",
      apiKey: "curr_fs_affected_mc",
      apiKey2: "",
    },
    {
      label: "Affected Villages",
      key: "curr_dss_affected_vill",
      apiKey: "curr_fs_affected_vills",
      apiKey2: "",
    },
    {
      label: "Affected Wards",
      key: "curr_dss_affected_wards",
      apiKey: "curr_fs_affected_wards",
      apiKey2: "",
    },
    {
      label: "Affected Population",
      key: "curr_dss_population_affected",
      apiKey: "curr_fs_pop_affected",
      apiKey2: "",
    },
    {
      label: "No. of people in Relief Camps",
      key: "curr_dss_relief_camps",
      apiKey: "curr_fs_ppl_evacuated",
      apiKey2: "",
    },
    {
      label: "Community Kitchen Running",
      key: "curr_dss_ckr",
      apiKey: "curr_fs_total_ck",
      apiKey2: "",
    },
    {
      label: "No. of people having meals today",
      key: "curr_dss_ppl_etng",
      apiKey: "curr_fs_ppl_eating",
      apiKey2: "",
    },
    {
      label: "No of Boats plying today",
      key: "curr_dss_totl_boats",
      apiKey: "curr_fs_total_boats",
      apiKey2: "",
    },
  ],
};
