import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LMSButton from "../../Common/LMSButton/LMSButton";
import { getData, postDataWithBody } from "../../../../Services/Services";
import { getCurrBarChartData } from "../../../../Services/EndPoints";
import DatePicker from "../../Common/Form/DatePicker/DatePicker";
import moment from "moment";
import { misStateReportConfig } from "./MISStateFloodReportConfig";
import { CSVLink } from "react-csv";

export default function MISStateFloodReportTable() {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  // const [selectedDistrictId, setSelectedDistrictId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const fetchTableData = async () => {
    let data = await postDataWithBody(
      getCurrBarChartData + "get_state_mis_report",
      { reportedOn: selectedDate, regionId: 199 },
      {}
    );
    setTableData(data);
  };

  // Array of keys representing the columns
  const columnKeys = Array.from({ length: 124 }, (_, i) => `headercol${i + 1}`);

  const headers = columnKeys.map((key, index) => ({
    label: t(`misdistfloodreport.${key}`),
    key: Object.values(misStateReportConfig)[index],
  }));
  return (
    <div>
      <div className="flood__report--container">
        <div className="ps-2 d-flex gap-2">
          <div className="d-flex">
            <div>
              <label className="mb-2">{t("floodreport.dated_on_lbl")}</label>
              <div className="date_picker_flood_container me-1">
                <DatePicker
                  selected={selectedDate}
                  defaultDateValue={moment().format("YYYY-MM-DD")}
                  maxDate={new Date().toISOString().split("T")[0]}
                  name="flood_date_picker"
                  setDate={setSelectedDate}
                  className="form-control date-picker date_picker_flood"
                />
              </div>
            </div>
            {/* <div>
              <label className="mb-2">{t("flooddisaster.datetxtto")}</label>
              <div className="date_picker_flood_container me-1">
                <DatePicker
                  selected={selectedDate}
                  defaultDateValue={moment().format("YYYY-MM-DD")}
                  maxDate={new Date().toISOString().split("T")[0]}
                  name="flood_date_picker"
                  setDate={setSelectedDate}
                  className="form-control date-picker date_picker_flood"
                />
              </div>
            </div>
   */}
          </div>

          <div className="d-flex align-items-end mb-3 gap-2">
            <LMSButton
              size="btn_sm"
              label={t("flooddisaster.apply")}
              onClick={fetchTableData}
            />
            <CSVLink
              data={tableData}
              headers={headers}
              filename={`MIS_State_Flood_Report_${selectedDate}.csv`}>
              <LMSButton size="btn_sm" label="Export" />
            </CSVLink>
          </div>
        </div>
      </div>
      <div className="p-3" style={{ overflowX: "auto" }}>
        <table
          className="table misflood_report-table"
          style={{ minWidth: "1500px" }}>
          <thead>
            <tr>
              {columnKeys.map((key) => (
                <th
                  className="misflood_report-table-border"
                  style={{ minWidth: "150px" }}
                  key={key}>
                  {t(`misdistfloodreport.${key}`)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData?.length > 0 ? (
              tableData.map((data, rowIndex) => (
                <tr className="misflood_report-table-border" key={rowIndex}>
                  {Object.values(misStateReportConfig).map(
                    (configKey, colIndex) => (
                      <td
                        className="misflood_report-table-border"
                        key={colIndex}>
                        {data[configKey] !== undefined &&
                        data[configKey] !== null
                          ? data[configKey]
                          : 0}
                      </td>
                    )
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columnKeys.length}>{t("No Data Available")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
