import React, { useEffect } from "react";
import DMSHeader from "../../DMSHeader/DMSHeader";
//import DailyFloodReportCompTable from "./DailyFloodReportCompTable";
import { useTranslation } from "react-i18next";
import DailyAffectedFloodReportForm from "./DailyAffectedFloodReportForm";

const DailyAffectedFloodReport = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="widget-container">
        <DMSHeader title="Form 1 - Daily Report" />
      </div>
      <div className="widget-container p-1">
        <DailyAffectedFloodReportForm />
      </div>
    </div>
  );
};

export default DailyAffectedFloodReport;
