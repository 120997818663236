import React, { useEffect } from "react";
import GridItem from "../../../../Components/UiComponents/GridItem/GridItem";
import { GridStack } from "gridstack";
import DMSHeader from "../../DMSHeader/DMSHeader";
import DailyFloodReportCompTable from "./DailyFloodReportCompTable";
import { useTranslation } from "react-i18next";

const DailyFloodReportComp = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="widget-container">
        <DMSHeader title={t("floodreport.title")} />
      </div>
      <div className="widget-container p-1">
        <DailyFloodReportCompTable />
      </div>
    </div>
  );
};

export default DailyFloodReportComp;
