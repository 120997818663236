import React, { useContext, useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import moment from "moment";
import { useTranslation } from "react-i18next";
import BlockPopUp from "./BlockPopUp";
import { getBlockInfoFloodURL } from "../../../Services/EndPoints";
import { getData } from "../../../Services/Services";
import { ThemeContext } from "../../..";
import BlockDetails from "./BlockDetails";
import ToggleView from "./ToggleView/ToggleView";
import NoData from "../../../Components/UiComponents/NoData/NoData";

const buttonStyle = {
  padding: "0.1rem 0.3rem",
  borderRadius: "0.3rem",
  backgroundColor: "#e28771",
  color: "#fff",
  fontSize: "0.7rem",
};
const FamilyAffectedTrendView = ({
  title,
  data,
  startDateStr,
  endDateStr,
  chartData,
  drillDownKey,
  activeTab,
}) => {
  const { theme } = useContext(ThemeContext);
  const echartsDom = useRef(null);
  const [chart, setChart] = useState(null);
  const [distName, setDistName] = useState();
  const [blockdata, setBlockdata] = useState([]);
  const [blockPopUp, setBlockPopUp] = useState(false);
  const [toggleLegends, setToggleLegends] = useState(true);
  const [labelColor, setLabelColor] = useState("#fff");
  const { t } = useTranslation();
  const [toggleChart, setToggleChart] = useState("bar");
  useEffect(() => {
    if (theme === "dark") setLabelColor("#fff");
    else if (theme === "light") setLabelColor("#909097");
  }, [theme]);
  useEffect(() => {
    if (activeTab === "currentStatus") {
      setToggleChart("bar");
    }
  }, [activeTab]);

  const handleToggle = () => {
    setBlockPopUp(!blockPopUp);
  };

  const fetchBlockData = async (
    dist_id,
    startDateStr,
    endDateStr,
    drillDownKey
  ) => {
    setBlockPopUp(true);
    try {
      if (activeTab === "dss") {
        const response = await getData(
          getBlockInfoFloodURL
            .replace("TODATE", endDateStr)
            .replace("DISTID", dist_id)
            .replace("APIKEY", drillDownKey)
            .replace("FROMDATE", startDateStr)
        );
        const filteredResponse = response.filter((item) => item.count > 0);
        setBlockdata(filteredResponse);
      } else {
        const response = await getData(
          getBlockInfoFloodURL
            .replace("TODATE", endDateStr)
            .replace("DISTID", dist_id)
            .replace("APIKEY", drillDownKey)
            .replace("FROMDATE", startDateStr)
        );
        const filteredResponse = response.filter((item) => item.count > 0);
        setBlockdata(filteredResponse);
      }
    } catch (error) {
      console.error("Error fetching block data:", error);
    }
  };
  const getChartOptions = () => {
    const sortedData = [...data].sort((a, b) => a.count - b.count);

    return {
      title: {
        left: "center",
        textStyle: {
          color: "#333",
          fontSize: 12,
          fontWeight: "bold",
        },
      },
      tooltip: {
        trigger: "axis",
        confine: true,
        triggerOn: "click",
        formatter: function (params) {
          const dist_id = sortedData[params[0].dataIndex].dist_id;
          const distname = sortedData[params[0].dataIndex].dist_name;
          setDistName(distname);
          fetchBlockData(dist_id, startDateStr, endDateStr, drillDownKey);
        },
      },
      grid: {
        left: "2%",
        top: "8%",
        right: "20%", // Adjusted to make space for labels
        bottom: "15%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        name: title,
        nameLocation: "middle",
        nameTextStyle: {
          padding: [30, 0, 0, 0],
          color: "#909097",
          fontWeight: "bold",
        },
        axisLabel: {
          textStyle: {
            color: "#909097",
            fontWeight: "bold",
          },
        },
      },
      yAxis: {
        type: "category",
        data: sortedData?.map((item) =>
          item?.dist_name ? item?.dist_name : item?.name
        ),
        name: t("flooddisaster.barchartyaxis"),
        nameLocation: "end",
        axisLabel: {
          overflow: "truncate",
          ellipsis: "...",
          show: true,
          color: labelColor,
          fontWeight: "bold",
        },
        axisTick: {
          show: false,
        },
      },
      dataZoom: [
        {
          type: "slider",
          yAxisIndex: 0,
          start: 0,
          end: 100,
          orient: "vertical",
          zoomLock: false,
          handleSize: "100%",
          handleStyle: {
            color: "#E95060",
          },
        },
      ],
      series: [
        {
          type: "bar",
          barWidth: 10,
          data: sortedData?.map((item) => item?.count),
          itemStyle: {
            color: "#E95060",
            borderRadius: [5, 5, 5, 5],
          },
          label: {
            show: true,
            position: "right", // Ensure labels stay to the right of the bar
            distance: 10, // Increase distance from the bar
            formatter: function (data) {
              if (data.value > 0) {
                return `{valueStyle|${data.value}}`;
              } else {
                return "";
              }
            },
            rich: {
              valueStyle: {
                color: "#18a3a0",
                fontSize: 14,
                borderWidth: 2,
                borderColor: "#18a3a0",
                padding: [5, 10, 2, 10], // Adjust padding for more space
                fontWeight: "bold",
                borderRadius: 10,
              },
            },
          },
        },
      ],
    };
  };

  // Prepare dateArr and idArr with formatted dates
  let dateArr = [];
  let idArr = [];

  for (var dataElem in chartData) {
    const formattedDate = moment(chartData[dataElem].reported_on_date).format(
      "DD-MM-YY"
    );

    if (!dateArr.includes(formattedDate)) {
      dateArr.push(formattedDate);
    }

    if (!idArr.includes(chartData[dataElem].id)) {
      idArr.push(chartData[dataElem].id);
    }
  }

  dateArr.sort(function (a, b) {
    let dateA = moment(a, "DD-MM-YY").toDate();
    let dateB = moment(b, "DD-MM-YY").toDate();
    return dateA - dateB;
  });

  let seriesArr = [];
  let idSeriesMap = {};

  for (let dataElem in chartData) {
    const formattedDate = moment(chartData[dataElem].reported_on_date).format(
      "DD-MM-YY"
    );

    if (idSeriesMap[chartData[dataElem].id]) {
      let seriesObj = idSeriesMap[chartData[dataElem].id];
      for (let dateElem in dateArr) {
        if (dateArr[dateElem] === formattedDate) {
          seriesObj["datamap"][formattedDate] = chartData[dataElem].count;
        }
      }
    } else {
      let seriesObj = {};

      seriesObj["name"] = chartData[dataElem].name;
      seriesObj["type"] = "line";

      let dateCountDataMap = {};

      for (let dateElem in dateArr) {
        if (dateArr[dateElem] === formattedDate) {
          dateCountDataMap[dateArr[dateElem]] = chartData[dataElem].count;
        } else {
          dateCountDataMap[dateArr[dateElem]] = null;
        }
      }

      seriesObj["datamap"] = dateCountDataMap;
      idSeriesMap[chartData[dataElem].id] = seriesObj;
    }
  }

  for (let series in idSeriesMap) {
    idSeriesMap[series]["data"] = Object.values(idSeriesMap[series]["datamap"]);
  }
  const getTrendChartOptions = () => {
    const generateColors = (numColors) => {
      const baseColors = [
        "#F32F0E",
        "#18A3A0",
        "#F69A06",
        "#83571c",
        "#F6F506",
        "#B3F606",
        "#36F606",
        "#06F6EE",
        "#069FF6",
        "#060BF6",
        "#BE06F6",
        "#F6069D",
        "#F60655",
        "#F378BA",
        "#470E3C",
      ];
      const extendedColors = [];

      for (let i = 0; i < numColors; i++) {
        extendedColors.push(baseColors[i % baseColors.length]);
      }

      return extendedColors;
    };

    const seriesCount = Object.values(idSeriesMap).length;
    const lineColors = generateColors(seriesCount);

    // Helper function to apply ellipsis to legend names
    const applyEllipsis = (text, maxLength) => {
      return text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    const seriesNames = Object.values(idSeriesMap).map(
      (seriesObj) => seriesObj.name
    );

    return {
      tooltip: {
        show: true,
        trigger: "item",
        formatter: (params) => {
          const date = params.name;
          const seriesName = params.seriesName;

          let tooltipContent = `${date}<br/>`;

          const districtData = chartData.find(
            (item) =>
              item.name === seriesName &&
              moment(item.reported_on_date).format("DD-MM-YY") === date
          );

          if (districtData) {
            const blk_names = districtData.blk_name;
            const blkNamesArray = blk_names.split(","); 
            const limitedBlkNames = blkNamesArray.reduce((acc, curr, index) => {
              const groupIndex = Math.floor(index / 4);
              acc[groupIndex] = acc[groupIndex] || [];
              acc[groupIndex].push(curr);
              return acc;
            }, []);

            tooltipContent += `${params.marker} ${seriesName}: ${params.value} <br/> Blocks:`;
            limitedBlkNames.forEach((group) => {
              tooltipContent += `${group.join(", ")}<br/>`;
            });
          }

          return tooltipContent;
        },
      },
      legend: {
        type: "scroll",
        orient: "vertical",
        right: "0",
        top: "5%",
        bottom: "15%",
        textStyle: {
          color: labelColor,
        },
        formatter: (name) => applyEllipsis(name, 10),
        data: seriesNames,
        selected: seriesNames.reduce((acc, seriesName) => {
          acc[seriesName] = toggleLegends; // Deselect all series by default
          return acc;
        }, {}),
      },
      xAxis: {
        type: "category",
        data: dateArr,
        axisLabel: {
          rotate: 45,
          textStyle: { color: labelColor },
        },
      },
      dataZoom: [
        {
          startValue: "2014-06-01",
        },
        {
          type: "inside",
        },
      ],
      grid: {
        left: "1%",
        top: "7%",
        right: "20%",
        bottom: "12%",
        containLabel: true,
      },
      yAxis: {
        type: "value",
        axisLabel: {
          textStyle: { color: labelColor },
        },
      },
      series: Object.values(idSeriesMap).map((seriesObj, index) => ({
        ...seriesObj,
        data: seriesObj.data.map((value) => (value === null ? 0 : value)),
        smooth: true,
        symbolSize: 15,
        emphasis: {
          focus: "series",
        },
        lineStyle: {
          width: 3,
          color: lineColors[index],
        },
        itemStyle: {
          color: lineColors[index],
        },
      })),
    };
  };

  useEffect(() => {
    if ((data && data.length > 0) || (chartData && chartData.length > 0)) {
      if (echartsDom.current) {
        const myChart = echarts.init(echartsDom.current);
        setChart(myChart);

        const handleResize = () => {
          myChart.resize();
        };
        window.addEventListener("resize", handleResize);

        const resizeObserver = new ResizeObserver(() => {
          myChart.resize();
        });
        resizeObserver.observe(echartsDom.current);

        return () => {
          window.removeEventListener("resize", handleResize);
          resizeObserver.disconnect();
          myChart.dispose();
        };
      }
    }
  }, [data, toggleChart, chartData, toggleLegends]);

  useEffect(() => {
    if (chart) {
      chart.clear();

      if (toggleChart === "bar" && data && data.length > 0) {
        chart.setOption(getChartOptions());
      } else if (
        toggleChart === "trendChart" &&
        chartData &&
        chartData.length > 0
      ) {
        chart.setOption(getTrendChartOptions());
      } else {
        chart.clear();
      }
    }
  }, [chart, toggleChart, data, chartData, t, theme, labelColor]);

  const componentRender = () => {
    if (toggleChart === "bar") {
      if (data?.length > 0) {
        return (
          <div ref={echartsDom} style={{ height: "100%", width: "100%" }} />
        );
      } else {
        return <NoData />;
      }
    } else {
      if (chartData?.length > 0) {
        return (
          <div ref={echartsDom} style={{ height: "100%", width: "100%" }} />
        );
      } else {
        return <NoData />;
      }
    }
  };
  return (
    <>
      <div className="card" style={{ width: "100%", height: "95%" }}>
        <div className="card-body" style={{ height: "100%" }}>
          <div className="d-flex justify-content-between">
            <div className="card-title">{title}</div>
            <div className="d-flex gap-2">
              {activeTab === "dss" && (
                <div>
                  <ToggleView
                    setActiveTab={setToggleChart}
                    activeTab={toggleChart}
                  />
                </div>
              )}
              {toggleChart === "trendChart" && (
                <div className="text-end mt-1">
                  <button
                    onClick={() => setToggleLegends(!toggleLegends)}
                    style={buttonStyle}>
                    {toggleLegends ? "Deselect All" : "Select All"}
                  </button>
                </div>
              )}
            </div>
          </div>

          {componentRender()}
        </div>
      </div>
      {blockPopUp && (
        <BlockPopUp
          title={`Affected District : ${distName}`}
          distName={distName}
          show={blockPopUp}
          handleToggle={handleToggle}
          blockdata={blockdata}
          component={<BlockDetails blockdata={blockdata} title={title} />}
        />
      )}
    </>
  );
};

export default FamilyAffectedTrendView;
