import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import DMS from "../Pages/DMS/DMS";
import Loader from "react-spinner-loader";
import { planning_routes_regs, lms_routes_regs, my_sop_mgmnt_regs, dmsdashboard, my_report_mgmnt_regs, resource_routes_regs, my_tasks_regs, region_routes_regs, agency_routes_regs, masterdatamgmnt_routes_regs, resource_entity_routes_regs, facility_routes_regs, masterresource_routes_regs, my_gis_mgmnt_regs, form_viewers } from "./DMSRoutes";
import RegistrationManagement from "../Pages/DMS/RegistrationManagement/RegistrationManagement";
import RegionManagement from "../Pages/DMS/RegionManagement/RegionManagement";
import AgencyManagement from "../Pages/DMS/AgencyManagement/AgencyManagement";
import TaskManagement from "../Pages/DMS/TaskManagement/TaskManagement";
import UserManagement from "../Pages/DMS/UserManagement/UserManagement";
import PrivateRoute from "./PrivateRoute";
import ResourceManagement from "../Pages/DMS/ResourceManagement/ResourceManagement";
import MasterDataManagement from "../Pages/DMS/MasterDataManagement/MasterDataManagement";
import FacilityManagement from "../Pages/DMS/FacilityManagement/FacilityManagement";
import MasterResourceManagement from "../Pages/DMS/MasterResourceManagement/MasterResourceManagement";
import MyTaskListManagement from "../Pages/DMS/MyTaskListManagement/MyTaskListManagement";
import GisManagement from "../Pages/DMS/GisManagement/GisManagement";
import ReportManagement from "../Pages/DMS/ReportManagement/ReportManagement";
import FormViewer from "../Pages/DMS/ReportManagement/FormViewer";
import FormRenderer from "../Pages/DMS/ReportManagement/FormRenderer";
import {
  getData
} from "../Services/Services";
import {
  getStoredFormTemplates, findPermissionsByUser
} from "../Services/EndPoints";
import FormViewerForm from "../Pages/DMS/ReportManagement/FormViewerForm";
import SOPExecutionManagement from "../Pages/DMS/GisManagement/SOPExecutionManagement";
import GISResourceManagement from "../Pages/DMS/GisManagement/GISResourceManagement";
import ReportBuilderManagement from "../Pages/DMS/GisManagement/ReportBuilderManagement";
import FormBuilder from "../Pages/DMS/ReportManagement/FormBuilder";
import SoPManagement from "../Pages/DMS/SOPManagement/SOPManagement";
import ContactManagement from "../Pages/DMS/ContactManagement/ContactManagement";
import DeploymentList from "../Pages/DMS/GisManagement/DeploymentList";
import { useKeycloak } from "@react-keycloak/web";
import UserTaskManagement from "../Pages/DMS/UserTaskManagement/UserTaskManagement";
import UserTaskForm from "../Pages/DMS/UserTaskManagement/UserTaskForm/UserTaskForm";
import AnimalShelter from "../Pages/DMS/Dashboard/AnimalShelter/AnimalShelter";
import UserProfile from "../Pages/UserProfile/UserProfile";
import StateDashboard from "../Pages/DMS/Dashboard/StateDashboard/StateDashboard";
import DMSDashboard from "../Pages/DMS/Dashboard/DMSDashboard/DMSDashboard";
import RTIncidentDashboard from "../Pages/DMS/GisManagement/RTIncidentDashboard";
const Router = () => {
  const [formViewerLinks, setFormViewerLinks] = useState([]);
  const [permissionArr, setPermissionArr] = useState(null);
  const [pemrssionLoaded, isPermissionLoaded] = useState(false);
  const [loader, setLoader] = useState(false);
  const { keycloak, initialized } = useKeycloak()
  useEffect(() => {
    if (keycloak.authenticated) {
      getPermissionList();
      addReportMenu();
    }
  }, [keycloak.authenticated])

  const getPermissionList = async () => {
    var permissionArr = await getData(findPermissionsByUser);
    setPermissionArr(permissionArr)
  };

  const getAdministrativeActIndexComponent = () => {
    let componentArr = {
      "2_0": <MasterDataManagement />,
      "6_1": <RegionManagement />,
      "10_2": <AgencyManagement />,
      "37_3": <UserManagement />,
      "18_4": <MasterDataManagement />,
      "38_5": <ReportManagement />,
      "39_6": <FormBuilder />
    };
    let data = [];
    permissionArr.forEach(function (permission) {
      if (permission.parent_id != null && permission.parent_id == 1) {
        data.push(permission);
      }
    });
    data = data.sort((a, b) => {
      if (a.menu_index < b.menu_index) {
        return -1;
      }
    });
    if (data.length > 0) {
      return componentArr[data[0]['id'] + '_' + data[0]['menu_index']]
    }
    else {
      return null;
    }

  }


  const getPlanningIndexComponent = () => {
    let componentArr = {
      "22_0": <ContactManagement />,
      "34_1": <FacilityManagement />,
      "35_2": <ResourceManagement />,
      "36_3": <SoPManagement />
    };
    let data = [];
    permissionArr.forEach(function (permission) {
      if (permission.parent_id != null && permission.parent_id == 26) {
        data.push(permission);
      }
    });
    data = data.sort((a, b) => {
      if (a.menu_index < b.menu_index) {
        return -1;
      }
    });
    if (data.length > 0) {
      return componentArr[data[0]['id'] + '_' + data[0]['menu_index']]
    }
    else {
      return null;
    }

  }
  const addReportMenu = async () => {
    var data = await getData(getStoredFormTemplates);
    let form_viewer_links = [];
    data.forEach(function (dataElems) {
      form_viewer_links.push(
        dataElems.form_name
      );
    });
    setFormViewerLinks(form_viewer_links);
  };


  const get = async () => {
    var data = await getData(getStoredFormTemplates);
    let form_viewer_links = [];
    data.forEach(function (dataElems) {
      form_viewer_links.push(
        dataElems.form_name
      );
    });
    setFormViewerLinks(form_viewer_links);
  };

  return (
    <Routes>
      {permissionArr != null && <Route path="/" element={<DMS />}>
        {/* <Route index element={<vehicle />} /> */}
       
        <Route
          index
          element={
            <PrivateRoute>
              {/* <DMSDashboard /> */}
              <StateDashboard/>
            </PrivateRoute>
          }
        />
        <Route path="/userProfile" element={<PrivateRoute><UserProfile /></PrivateRoute>} />
        <Route
          path="static"
          element={
            <PrivateRoute>
              <MyTaskListManagement />
            </PrivateRoute>
          }
        />
        <Route path="users" >
          <Route index element={<UserManagement />} />
          {lms_routes_regs.map(({ name, component }, i) => (
            <Route
              key={i}
              path={name}
              element={<PrivateRoute>{component}</PrivateRoute>}
            />
          ))}
        </Route>

        <Route path="resource-list" element={<RegistrationManagement />}>
          <Route index element={<ResourceManagement />} />
          {resource_routes_regs.map(({ name, component }, i) => (
            <Route
              key={i}
              path={name}
              element={<PrivateRoute>{component}</PrivateRoute>}
            />
          ))}
        </Route>

        <Route path="master-resource" element={<RegistrationManagement />}>
          <Route index element={<MasterResourceManagement />} />
          {masterresource_routes_regs.map(({ name, component }, i) => (
            <Route
              key={i}
              path={name}
              element={<PrivateRoute>{component}</PrivateRoute>}
            />
          ))}
        </Route>
        <Route path="dist-dashboard" element={<RegistrationManagement />}>
          <Route index element={<StateDashboard />} />
          {dmsdashboard.map(({ name, component }, i) => (
            <Route
              key={i}
              path={name}
              element={<PrivateRoute>{component}</PrivateRoute>}
            />
          ))}
        </Route>


        <Route path="my-tasks" element={<MyTaskListManagement />}>
          <Route index element={<MyTaskListManagement />} />
          {my_tasks_regs.map(({ name, component }, i) => (
            <Route
              key={i}
              path={name}
              element={<PrivateRoute>{component}</PrivateRoute>}
            />
          ))}
        </Route>

        <Route path="reportbuilder">
          <Route index element={<ReportBuilderManagement />} />
        </Route>
        <Route path="dist-dashboard/incidentdashboard">
          <Route index element={<RTIncidentDashboard />} />
        </Route>

        

        <Route path="region-list">
          <Route index element={<RegionManagement />} />
          {region_routes_regs.map(({ name, component }, i) => (
            <Route
              key={i}
              path={name}
              element={<PrivateRoute>{component}</PrivateRoute>}
            />
          ))}
        </Route>
        <Route path="agency-list">
          <Route index element={<AgencyManagement />} />
          {agency_routes_regs.map(({ name, component }, i) => (
            <Route
              key={i}
              path={name}
              element={<PrivateRoute>{component}</PrivateRoute>}
            />
          ))}
        </Route>
        <Route path="masterdata-table">
          <Route index element={getAdministrativeActIndexComponent()} />
          {masterdatamgmnt_routes_regs.map(({ name, component }, i) => (
            <Route
              key={i}
              path={name}
              element={<PrivateRoute>{component}</PrivateRoute>}
            />
          ))}
        </Route>




        <Route path="planning">
          <Route index element={getPlanningIndexComponent()} />
          {planning_routes_regs.map(({ name, component }, i) => (
            <Route
              key={i}
              path={name}
              element={<PrivateRoute>{component}</PrivateRoute>}
            />
          ))}
        </Route>

        <Route path="facility-mgmt">
          <Route index element={<FacilityManagement />} />
          {facility_routes_regs.map(({ name, component }, i) => (
            <Route
              key={i}
              path={name}
              element={<PrivateRoute>{component}</PrivateRoute>}
            />
          ))}
        </Route>
        <Route path="gismanagement">
          <Route index element={<GisManagement />} />
          {my_gis_mgmnt_regs.map(({ name, component }, i) => (
            <Route
              key={i}
              path={name}
              element={<PrivateRoute>{component}</PrivateRoute>}
            />
          ))}
        </Route>

        <Route path="/my-tasks/view-usertask">
          <Route index element={<UserTaskManagement />} />
        </Route>

        <Route path="/my-tasks/create-usertask">
          <Route index element={<UserTaskForm />} />
        </Route>

        <Route path="response-relief">
          <Route index element={<DeploymentList />} />
        </Route>

        <Route path="sopexecution">
          <Route index element={<SOPExecutionManagement />} />
        </Route>



        <Route path="resources">
          <Route index element={<GISResourceManagement />} />
        </Route>
        <Route path="dmsdashboard">
          <Route index element={<DMSDashboard />} />
          {dmsdashboard.map(({ name, component }, i) => (
            <Route
              key={i}
              path={name}
              element={<PrivateRoute>{component}</PrivateRoute>}
            />
          ))}
        </Route>

        <Route path="reportmanagement">
          <Route index element={<ReportManagement />} />
          {my_report_mgmnt_regs.map(({ name, component }, i) => (
            <Route
              key={i}
              path={name}
              element={<PrivateRoute>{component}</PrivateRoute>}
            />
          ))}
        </Route>

        {my_report_mgmnt_regs.map(({ name, component }, i) => (
          <Route
            key={i}
            path={name}
            element={<PrivateRoute>{component}</PrivateRoute>}
          />
        ))}

        <Route path="report-view/:formName">
          <Route index element={<PrivateRoute><FormViewer /></PrivateRoute>} />
        </Route>
        <Route path="create-form-view/:formName">
          <Route index element={<PrivateRoute><FormRenderer /></PrivateRoute>} />
        </Route>
        {/* <Route path="userProfile" >
          <Route index element={<PrivateRoute><UserProfile /></PrivateRoute>} /> </Route> */}

      </Route>}
    </Routes>
  );
};

export default Router;
