import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./FacilityTypeView.css";

const FacilityTypeView = (props) => {
  const { title, config, dataval } = props;
  const [rangetxt, setRangetxt] = useState("");
  const [data, setData] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    // Calculate total meals served and update the dataval with the correct keyName
    const totalMealsServed = (dataval["Breakfast"] || 0) + (dataval["Lunch"] || 0) + (dataval["Dinner"] || 0);
    const updatedData = { ...dataval, "Total Meals served": totalMealsServed };

    if (props?.range === "TODAY") {
      setRangetxt("From Yesterday");
    } else if (props?.range === "WEEK") {
      setRangetxt("From Last Week");
    } else if (props?.range === "MONTH") {
      setRangetxt("From Last Month");
    }
    setData(updatedData);
  }, [props]);

  const getBackgroundClass = (index) => {
    const backgroundClasses = [
      "singleCardBlue",
      "singleCardperpel",
      "singleCardorange",
      "singleCardpink",
    ];
    return backgroundClasses[index % backgroundClasses.length];
  };

  return (
    <div class="card">
      <div class="card-body">
        <div class="card-title d-flex justify-content-between">{title}</div>
        {config?.map((conf, index) => {
          return (
            <div key={index} className={getBackgroundClass(index)}>
              <div>{conf.label}</div>
              <div>{data[conf.keyName] || 0}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FacilityTypeView;
