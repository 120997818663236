import React, { useState } from 'react';
import './ToggleViewBmsk.css';

export default function ToggleViewBmsk({ activeTabBmsk, setActiveTabBmsk }) {

    const handleTabClick = (tabName) => {
        setActiveTabBmsk(tabName);

    };

    return (
        <div className="toggle-switch-container">
             <div
                className={`toggle-item ${activeTabBmsk === 'Charts' ? 'active' : ''}`}
                onClick={() => handleTabClick('Charts')}
            >
                Charts
            </div>
            <div
                className={`toggle-item ${activeTabBmsk === 'BMSK' ? 'active' : ''}`}
                onClick={() => handleTabClick('BMSK')}
            >
                BMSK
            </div>
        </div>
    );
}
