import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import "./OpenCloseChart.css";

export default function OpenCloseChart({ dataval }) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <span className="card-title">Open vs Closed</span>
        </div>
        <div className="bar-container">

          {/* Community Kitchen */}
          <div className="mb-4">
            <div className="d-flex justify-content-between mt-3">
              <div className="bar-label">Community Kitchen</div>
            </div>
            <ProgressBar
              completed={dataval?.community_kitchen?.open}
              maxCompleted={dataval?.community_kitchen?.open + dataval?.community_kitchen?.closed}
              bgColor="#378511" // Green for open
              baseBgColor="#FF5869" // Red for closed
              isLabelVisible={false}
              height="12px"
            />
            <div className="d-flex justify-content-between small text-muted mt-1">
              <div className="text-success">Open: {dataval?.community_kitchen?.open}</div>
              <div className="text-danger">Closed: {dataval?.community_kitchen?.closed}</div>
            </div>
          </div>

          {/* Flood Relief Camp */}
          <div className="mb-4">
            <div className="d-flex justify-content-between">
              <div className="bar-label">Flood Relief Camp</div>
            </div>
            <ProgressBar
              completed={dataval?.flood_relief?.open}
              maxCompleted={dataval?.flood_relief?.open + dataval?.flood_relief?.closed}
              bgColor="#378511"
              baseBgColor="#FF5869"
              isLabelVisible={false}
              height="12px"
            />
            <div className="d-flex justify-content-between small text-muted mt-1">
              <div className="text-success">Open: {dataval?.flood_relief?.open}</div>
              <div className="text-danger">Closed: {dataval?.flood_relief?.closed}</div>
            </div>
          </div>

          {/* Animal Shelters */}
          <div>
            <div className="d-flex justify-content-between">
              <div className="bar-label">Animal Shelter</div>
            </div>
            <ProgressBar
              completed={dataval?.animal_shelter?.open}
              maxCompleted={dataval?.animal_shelter?.open + dataval?.animal_shelter?.closed}
              bgColor="#378511"
              baseBgColor="#FF5869"
              isLabelVisible={false}
              customLabel="open"
              height="12px"
            />
            <div className="d-flex justify-content-between small text-muted mt-1">
              <div className="text-success">Open: {dataval?.animal_shelter?.open}</div>
              <div className="text-danger">Closed: {dataval?.animal_shelter?.closed}</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
