import React, { useState, useEffect, useRef, useContext } from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import govt_logo from "../../../../../Assets/Images/bihar_logo.png";
import Loader from "react-spinner-loader";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { useTranslation } from "react-i18next";
import DatePicker from "../../../Common/Form/DatePicker/DatePicker.js";
import { getPartiallyAffectedDailyReportByState } from "../../../../../Services/EndPoints.js";
import { postDataWithBody } from "../../../../../Services/Services.js";
import moment from "moment";

const PartiallyAffectedReportTable = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [dailyReport, setDailyReport] = useState(null);
  const [blockData, setBlockData] = useState([]);
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);
  const [slctdBlkId, setSlctdBlkId] = useState(null);
  const pdfRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const getBlockIdListForReport = () => {
    if (slctdBlkId == 0) {
      let blockIdList = [];
      blockData.forEach((v) => blockIdList.push(v.id));
      return blockIdList;
    } else {
      return [slctdBlkId];
    }
  };

  const getDailyReportData = async (blockListId) => {
    if (blockListId == null) {
      blockListId = getBlockIdListForReport();
    }
    var selectedDateStr = document.getElementById("flood_date_picker").value;
    if (selectedDateStr == "") {
      selectedDateStr = new Date().toISOString().split("T")[0];
    }
    setSelectedDate(selectedDateStr);
    setLoader(true);

    const index = blockListId.indexOf(0);
    if (index > -1) {
      // only splice array when item is found
      blockListId.splice(index, 1); // 2nd parameter means remove one item only
    }
    let inputobj = {
      reported_on: selectedDateStr,
    };

    let dailyReportData = await postDataWithBody(
      getPartiallyAffectedDailyReportByState,
      inputobj,
      {}
    );
    setDailyReport(dailyReportData);

    setLoader(false);
    var skillsSelect = document.getElementById("district_drop");
    var selectedText = skillsSelect?.options[skillsSelect.selectedIndex].text;
    document.getElementById("selected_dist_name").innerHTML = selectedText;
  };

  async function addImageProcess() {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = govt_logo;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  }

  const partiallyAffectedDownloadPDF = async () => {
    const content = document.getElementById("form1_table");
    const doc = new jsPDF("p", "pt", "a4");
    let selectedDate = new Date(
      document.getElementById("flood_date_picker").value
    ).toLocaleDateString("en-GB");

    try {
      const image = await addImageProcess();
      let fontName = "Helvetica";

      if (i18n.language == "hi") {
        fontName = "Akshar Unicode";
      }
      doc.setFont(fontName);
      doc.setFontSize(13);
      doc.addImage(image, "png", 255, 25, 0, 0);

      var xOffset = doc.internal.pageSize.width / 2;
      doc.text(t("floodreport.govt_name"), xOffset, 120, { align: "center" });
      doc.text(t("floodreport.department_name"), xOffset, 140, {
        align: "center",
      });
      doc.text(
        t("floodreport.state_flood_report_nm") +
          " (" +
          t("floodreport.dated_on_lbl") +
          " " +
          selectedDate +
          ")",
        xOffset,
        160,
        { align: "center" }
      );

      doc.autoTable({
        html: content,
        startX: 10,
        startY: 190,
        theme: "grid",
        rowPageBreak: "avoid",
        bodyStyles: { lineColor: [0, 0, 0] },
        columnStyles: {
          // Set column widths to match affectedReportDownloadPDF
          2: { cellWidth: 52 }, // Column 2
          3: { cellWidth: 52 }, // Column 3
          4: { cellWidth: 52 }, // Column 4
          5: { cellWidth: 41 }, // Column 5
          6: { cellWidth: 41 }, // Column 6
          7: { cellWidth: 41 }, // Column 7
          8: { cellWidth: 52 }, // Column 8
          9: { cellWidth: 52 }, // Column 9
          10: { cellWidth: 52 }, // Column 10
          11: { cellWidth: 0 }, // Optional column with no width
        },
        didParseCell: function (data) {
          if (data.cell && data.cell.raw) {
            if (
              data.cell.raw.querySelector &&
              data.cell.raw.querySelector("b")
            ) {
              data.cell.styles.textColor = "red";
            }

            if (
              data.cell.raw.querySelector &&
              data.cell.raw.querySelector("span")
            ) {
              data.cell.styles.halign = "center";
            }
          }
        },
        styles: {
          fontSize: 9,
          cellWidth: "auto",
          halign: "left",
          cellPadding: 3,
          minCellWidth: 4,
        },
      });

      doc.save("partially_affected_report_bihar_state.pdf");
    } catch (error) {
      console.error("Error adding image:", error);
    }
  };

  useEffect(() => {}, []);

  const getFormattedDate = (dateStr) => {
    return <Moment format="DD/MM/YYYY">{dateStr}</Moment>;
  };

  const changeSelectedate = () => {
    if (selectedDistrictId && selectedDate) {
      getDailyReportData(selectedDistrictId, selectedDate);
    } else {
      console.error("Please select both district and date.");
    }
  };
  return (
    <div class="flood__report--container">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="ps-2 d-flex">
        <div>
          <label className="mb-2">{t("floodreport.reported_on")}</label>
          <div className="date_picker_flood_container me-1">
            <DatePicker
              selected={selectedDate}
              defaultDateValue={moment().format("YYYY-MM-DD")}
              maxDate={new Date().toISOString().split("T")[0]}
              name="flood_date_picker"
              onChange={(date) => {
                console.log("Selected date:", date);
                setSelectedDate(date);
              }}
              className="form-control date-picker date_picker_flood"
            />
          </div>
        </div>
        <button
          className="btn btn-success ms-2 btn-sm flood__report--button"
          onClick={() => getDailyReportData(null)}>
          Generate
        </button>
        <button
          className="btn btn-success btn-sm flood__report--button"
          onClick={partiallyAffectedDownloadPDF}>
          {t("floodreport.download")}
        </button>
      </div>
      <div className="pe-3" ref={pdfRef}>
        <div className="text-center fw-bold d-none">
          Daily Flood Report Dated on ({getFormattedDate(selectedDate)})
        </div>
        <table id="form1_table" class="flood__report--table" border="2">
          <tr>
            <td class="table__data--align" colSpan="1">
              <b class="table__data--sno_dist_heading">
                <span> {t("floodreport.slno")}</span>
              </b>
            </td>
            <td class="table__data--align" colSpan="11">
              <b class="table__data--sno_dist_heading">
                <span>State :</span>
                <span class="d-inline ms-1">Bihar</span>
              </b>
            </td>
          </tr>
          <tr>
            <td class="table__data--align" rowSpan="2">
              <span> {t("floodreport.one")}</span>
            </td>
            <td colSpan="5">
              <b class="table__data--heading">
                Total Number of Parially Affected Districts
              </b>
            </td>
            <td colSpan="9">
              {dailyReport != null &&
              dailyReport.block_affected[0] != null &&
              dailyReport.block_affected[0].pa_dist_affected_list
                ? dailyReport.block_affected[0].pa_dist_affected_list
                    .split(",")
                    .filter(Boolean).length
                : 0}
            </td>
          </tr>
          <tr>
            <td colSpan="5">
              <b className="table__data--heading">
                Name of the Partially Affected Districts
              </b>
            </td>
            <td colSpan="9">
              {dailyReport != null &&
                dailyReport.block_affected[0] != null &&
                dailyReport.block_affected[0].pa_dist_affected_list
                  .split(",")
                  .join(", ")}
            </td>
          </tr>

          <tr>
            <td class="table__data--align" rowSpan="2">
              <span>{t("floodreport.two")}</span>
            </td>
            <td rowSpan="2" class="table__data--align">
              <b class="table__data--heading">{t("floodreport.celltitle")}</b>
            </td>
            <td colSpan="7">{t("floodreport.reportdata3")}</td>
            <td colSpan="10">
              {dailyReport != null &&
                dailyReport.dr_geo_affected != null &&
                dailyReport.dr_geo_affected[0] != null &&
                dailyReport.dr_geo_affected[0].totl_grm_pnchyt}
            </td>
          </tr>
          <tr>
            <td colSpan="7">{t("floodreport.reportdata4")}</td>
            <td colSpan="10">
              {dailyReport != null &&
                dailyReport.dr_geo_affected != null &&
                dailyReport.dr_geo_affected[0] != null &&
                dailyReport.dr_geo_affected[0].nos_vilge_aftd}
            </td>
          </tr>

          <tr>
            <td class="table__data--align" rowSpan="5">
              <span>{t("floodreport.three")}</span>
            </td>
            <td rowSpan="5" class="table__data--align">
              <b class="table__data--heading">{t("floodreport.celltitle1")}</b>
            </td>
            <td colSpan="7">{t("floodreport.reportdata5")}</td>
            <td colSpan="10">
              {dailyReport != null &&
                dailyReport.dr_geo_affected != null &&
                dailyReport.dr_geo_affected[0] != null &&
                dailyReport.dr_geo_affected[0].totl_ngr_pnchyt}
            </td>
          </tr>
          <tr>
            <td colSpan="7">{t("floodreport.reportdata63")}</td>
            <td colSpan="10">
              {dailyReport != null &&
                dailyReport.dr_geo_affected != null &&
                dailyReport.dr_geo_affected[0] != null &&
                dailyReport.dr_geo_affected[0].totl_mucpl_corp}
            </td>
          </tr>
          <tr>
            <td colSpan="7">{t("floodreport.reportdata6")}</td>
            <td colSpan="10">
              {dailyReport != null &&
                dailyReport.dr_geo_affected != null &&
                dailyReport.dr_geo_affected[0] != null &&
                dailyReport.dr_geo_affected[0].totl_ngr_parsad}
            </td>
          </tr>
          <tr>
            <td colSpan="7">{t("floodreport.reportdata7")}</td>
            <td colSpan="10">
              {dailyReport != null &&
                dailyReport.dr_geo_affected != null &&
                dailyReport.dr_geo_affected[0] != null &&
                dailyReport.dr_geo_affected[0].nos_wrd_aftd}
            </td>
          </tr>
          <tr>
            <td colSpan="7">{t("floodreport.reportdata8")}</td>
            <td colSpan="10">
              {dailyReport != null &&
                dailyReport.dr_geo_affected != null &&
                dailyReport.dr_geo_affected[0] != null &&
                dailyReport.dr_geo_affected[0].totl_aftd}
            </td>
          </tr>

          <tr>
            <td class="table__data--align" rowSpan="5">
              <span>{t("floodreport.four")}</span>
            </td>
            <td rowSpan="2"></td>
            <td colSpan="3">
              <b class="table__data--heading ">
                {t("floodreport.reportdata9")}
              </b>
            </td>
            <td colSpan="3">
              <b class="table__data--heading ">
                {t("floodreport.reportdata10")}
              </b>
            </td>
            <td colSpan="3">
              <b class="table__data--heading ">
                {t("floodreport.reportdata11")}{" "}
              </b>
            </td>
          </tr>
          <tr>
            <td>{t("floodreport.untilruraldata")} </td>
            <td>{t("floodreport.untilurbandata")}</td>
            <td>{t("floodreport.untiltotaldata")}</td>
            <td>{t("floodreport.todayruraldata")}</td>
            <td>{t("floodreport.todayurbandata")}</td>
            <td>{t("floodreport.todaytotaldata")}</td>
            <td>{t("floodreport.popltnruraldata")}</td>
            <td>{t("floodreport.popltnurbandata")}</td>
            <td>{t("floodreport.popltntotaldata")}</td>
          </tr>

          <tr>
            <td class="table__data--align">
              {" "}
              <b>
                {t("floodreport.a")} , {t("floodreport.celltitle3")}
              </b>
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected[0] != null &&
                dailyReport.dr_population_affected[0].aftd_pop_rural_ystd}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected[0] != null &&
                dailyReport.dr_population_affected[0].aftd_pop_urban_ystd}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected[0] != null &&
                dailyReport.dr_population_affected[0].aftd_pop_totl_ystd}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected[0] != null &&
                dailyReport.dr_population_affected[0].aftd_pop_rural_today}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected[0] != null &&
                dailyReport.dr_population_affected[0].aftd_pop_urban_today}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected[0] != null &&
                dailyReport.dr_population_affected[0].aftd_pop_totl_today}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected[0] != null &&
                dailyReport.dr_population_affected[0].aftd_pop_rural}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected[0] != null &&
                dailyReport.dr_population_affected[0].aftd_pop_urban}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected[0] != null &&
                dailyReport.dr_population_affected[0].totl_pop_aftd}
            </td>
          </tr>
          <tr>
            <td class="table__data--align">
              <b>
                {t("floodreport.b")} , {t("floodreport.celltitle4")}
              </b>
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected.length > 0 &&
                dailyReport.dr_population_affected[0].aftd_fam_rural_ystd}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected.length > 0 &&
                dailyReport.dr_population_affected[0].aftd_fam_urban_ystd}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected.length > 0 &&
                dailyReport.dr_population_affected[0].aftd_fam_totl_ystd}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected.length > 0 &&
                dailyReport.dr_population_affected[0].aftd_fam_rural_today}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected.length > 0 &&
                dailyReport.dr_population_affected[0].aftd_fam_urban_today}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected.length > 0 &&
                dailyReport.dr_population_affected[0].aftd_fam_totl_today}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected.length > 0 &&
                dailyReport.dr_population_affected[0].aftd_fam_rural}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected.length > 0 &&
                dailyReport.dr_population_affected[0].aftd_fam_urban}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected.length > 0 &&
                dailyReport.dr_population_affected[0].totl_fam_aftd}
            </td>
          </tr>
          <tr>
            <td class="table__data--align">
              <b>
                {t("floodreport.c")} . {t("floodreport.celltitle5")}
              </b>
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected.length > 0 &&
                dailyReport.dr_population_affected[0].aftd_anml_rural_ystd}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected.length > 0 &&
                dailyReport.dr_population_affected[0].aftd_anml_urban_ystd}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected.length > 0 &&
                dailyReport.dr_population_affected[0].aftd_anml_totl_ystd}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected.length > 0 &&
                dailyReport.dr_population_affected[0].aftd_anml_rural_today}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected.length > 0 &&
                dailyReport.dr_population_affected[0].aftd_anml_urban_today}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected.length > 0 &&
                dailyReport.dr_population_affected[0].aftd_anml_totl_today}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected.length > 0 &&
                dailyReport.dr_population_affected[0].aftd_anml_rural}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected.length > 0 &&
                dailyReport.dr_population_affected[0].aftd_anml_urban}
            </td>
            <td>
              {dailyReport != null &&
                dailyReport.dr_population_affected != null &&
                dailyReport.dr_population_affected.length > 0 &&
                dailyReport.dr_population_affected[0].totl_anml_aftd}
            </td>
          </tr>
          <tr>
            <td class="table__data--align">
              <span>{t("floodreport.five")}</span>
            </td>
            <td colSpan="5">
              <b class="table__data--heading">
                {t("floodreport.reportdata12")}
              </b>
            </td>

            <td colSpan="6">
              {" "}
              {dailyReport != null &&
                dailyReport.flood_river_map != null &&
                dailyReport.flood_river_map.length > 0 &&
                dailyReport.flood_river_map[0].river_list}
            </td>
          </tr>
          <tr>
            <td class="table__data--align" rowSpan="7">
              <span>{t("floodreport.six")}</span>
            </td>

            <td colSpan="11">
              <b class="table__data--heading">
                {t("floodreport.reportdata13")}
              </b>
            </td>
          </tr>

          <tr>
            <td colSpan="8">{t("floodreport.reportdata14")}</td>
            <td colSpan="10">
              {dailyReport != null &&
                dailyReport.dr_flood_impact != null &&
                dailyReport.dr_flood_impact.length > 0 &&
                dailyReport.dr_flood_impact[0].affected_blocks_count}
            </td>
          </tr>
          <tr>
            <td colSpan="8">{t("floodreport.reportdata15")} </td>
            <td colSpan="10">
              {dailyReport != null &&
                dailyReport.dr_flood_impact != null &&
                dailyReport.dr_flood_impact.length > 0 &&
                dailyReport.dr_flood_impact[0].affected_gram_panchayats}
            </td>
          </tr>

          <tr>
            <td colSpan="8">{t("floodreport.reportdata16")} </td>
            <td colSpan="10">
              {dailyReport != null &&
                dailyReport.dr_flood_impact != null &&
                dailyReport.dr_flood_impact.length > 0 &&
                dailyReport.dr_flood_impact[0].affected_municipal_bodies}
            </td>
          </tr>
          <tr>
            <td colSpan="8">{t("floodreport.reportdata17")}</td>
            <td colSpan="10">
              {dailyReport != null &&
                dailyReport.dr_flood_impact != null &&
                dailyReport.dr_flood_impact.length > 0 &&
                dailyReport.dr_flood_impact[0].affected_villages}
            </td>
          </tr>
          <tr>
            <td colSpan="8">{t("floodreport.reportdata18")} </td>
            <td colSpan="10">
              {dailyReport != null &&
                dailyReport.dr_flood_impact != null &&
                dailyReport.dr_flood_impact.length > 0 &&
                dailyReport.dr_flood_impact[0].affected_wards}
            </td>
          </tr>
          <tr>
            <td colSpan="8">{t("floodreport.reportdata19")} </td>
            <td colSpan="10">
              {dailyReport != null &&
                dailyReport.dr_flood_impact != null &&
                dailyReport.dr_flood_impact.length > 0 &&
                dailyReport.dr_flood_impact[0].affected_population}
            </td>
          </tr>

          <tr>
            <td class="table__data--align" rowSpan="5">
              <span>{t("floodreport.seven")}</span>
            </td>
            <td colSpan="5">
              <b class="table__data--heading">{t("floodreport.celltitle6")}</b>
            </td>
            <td colSpan="3">
              <b class="table__data--heading">{t("floodreport.celltitle7")}</b>
            </td>
            <td colSpan="6">
              <b class="table__data--heading">{t("floodreport.celltitle8")}</b>
            </td>
          </tr>
          <tr>
            <td colSpan="5"> {t("floodreport.reportdata20")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_area_affected != null &&
                dailyReport.dr_area_affected.length > 0 &&
                dailyReport.dr_area_affected[0].agricultural_area_affected_curr}
            </td>

            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_area_affected != null &&
                dailyReport.dr_area_affected.length > 0 &&
                dailyReport.dr_area_affected[0]
                  .agricultural_area_affected_till_now}
            </td>
          </tr>
          <tr>
            <td colSpan="5">{t("floodreport.reportdata21")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_area_affected != null &&
                dailyReport.dr_area_affected.length > 0 &&
                dailyReport.dr_area_affected[0]
                  .non_agricultural_area_affected_curr}
            </td>

            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_area_affected != null &&
                dailyReport.dr_area_affected.length > 0 &&
                dailyReport.dr_area_affected[0]
                  .non_agricultural_area_affected_till_now}
            </td>
          </tr>
          <tr>
            <td colSpan="5">{t("floodreport.reportdata22")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_area_affected != null &&
                dailyReport.dr_area_affected.length > 0 &&
                dailyReport.dr_area_affected[0].damaged_crop_area_affected_curr}
            </td>

            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_area_affected != null &&
                dailyReport.dr_area_affected.length > 0 &&
                dailyReport.dr_area_affected[0]
                  .damaged_crop_area_affected_till_now}
            </td>
          </tr>
          <tr>
            <td colSpan="5">{t("floodreport.reportdata23")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_area_affected != null &&
                dailyReport.dr_area_affected.length > 0 &&
                dailyReport.dr_area_affected[0]
                  .estimated_value_of_damaged_crops_affected_curr}
            </td>

            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_area_affected != null &&
                dailyReport.dr_area_affected.length > 0 &&
                dailyReport.dr_area_affected[0]
                  .estimated_value_of_damaged_crops_affected_till_now}
            </td>
          </tr>

          <tr>
            <td class="table__data--align" rowSpan="9">
              <span>{t("floodreport.eight")}</span>
            </td>
            <td colSpan="5">
              <b class="table__data--heading">{t("floodreport.celltitle9")}</b>
            </td>
            <td colSpan="3">
              <b class="table__data--heading">{t("floodreport.celltitle10")}</b>
            </td>
            <td colSpan="6">
              <b class="table__data--heading">{t("floodreport.celltitle11")}</b>
            </td>
          </tr>
          <tr>
            <td rowSpan="2">
              <b class="table__data--heading">{t("floodreport.celltitle12")}</b>
            </td>
            <td colSpan="4">{t("floodreport.celltitle13")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_damaged_house != null &&
                dailyReport.dr_damaged_house.length > 0 &&
                dailyReport.dr_damaged_house[0].ktha_cmplt_today}
            </td>
            <td colSpan="7">
              {dailyReport != null &&
                dailyReport.dr_damaged_house != null &&
                dailyReport.dr_damaged_house.length > 0 &&
                dailyReport.dr_damaged_house[0].ktha_cmplt}
            </td>
          </tr>
          <tr>
            <td colSpan="4">{t("floodreport.celltitle14")}</td>

            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_damaged_house != null &&
                dailyReport.dr_damaged_house.length > 0 &&
                dailyReport.dr_damaged_house[0].ktha_prtl_today}
            </td>
            <td colSpan="6">
              {dailyReport != null &&
                dailyReport.dr_damaged_house != null &&
                dailyReport.dr_damaged_house.length > 0 &&
                dailyReport.dr_damaged_house[0].ktha_prtl}
            </td>
          </tr>
          <tr>
            <td rowSpan="2">
              <b class="table__data--heading">{t("floodreport.celltitle15")}</b>
            </td>
            <td colSpan="4">{t("floodreport.celltitle16")}</td>

            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_damaged_house != null &&
                dailyReport.dr_damaged_house.length > 0 &&
                dailyReport.dr_damaged_house[0].crct_cmplt_today}
            </td>
            <td colSpan="7">
              {dailyReport != null &&
                dailyReport.dr_damaged_house != null &&
                dailyReport.dr_damaged_house.length > 0 &&
                dailyReport.dr_damaged_house[0].crct_cmplt}
            </td>
          </tr>
          <tr>
            <td colSpan="4">{t("floodreport.celltitle17")}</td>

            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_damaged_house != null &&
                dailyReport.dr_damaged_house.length > 0 &&
                dailyReport.dr_damaged_house[0].crct_prtl_today}
            </td>
            <td colSpan="6">
              {dailyReport != null &&
                dailyReport.dr_damaged_house != null &&
                dailyReport.dr_damaged_house.length > 0 &&
                dailyReport.dr_damaged_house[0].crct_prtl}
            </td>
          </tr>

          <tr>
            <td colSpan="5">{t("floodreport.reportdata24")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_damaged_house != null &&
                dailyReport.dr_damaged_house.length > 0 &&
                dailyReport.dr_damaged_house[0].ctg_hut_today}
            </td>
            <td colSpan="6">
              {dailyReport != null &&
                dailyReport.dr_damaged_house != null &&
                dailyReport.dr_damaged_house.length > 0 &&
                dailyReport.dr_damaged_house[0].ctg_hut}
            </td>
          </tr>
          <tr>
            <td colSpan="5">{t("floodreport.reportdata25")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_damaged_house != null &&
                dailyReport.dr_damaged_house.length > 0 &&
                dailyReport.dr_damaged_house[0].anml_shd_today}
            </td>
            <td colSpan="6">
              {dailyReport != null &&
                dailyReport.dr_damaged_house != null &&
                dailyReport.dr_damaged_house.length > 0 &&
                dailyReport.dr_damaged_house[0].anml_shd}
            </td>
          </tr>

          <tr>
            <td colSpan="5">{t("floodreport.reportdata26")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_damaged_house != null &&
                dailyReport.dr_damaged_house.length > 0 &&
                dailyReport.dr_damaged_house[0].estd_hse_val_today}
            </td>
            <td colSpan="6">
              {dailyReport != null &&
                dailyReport.dr_damaged_house != null &&
                dailyReport.dr_damaged_house.length > 0 &&
                dailyReport.dr_damaged_house[0].estd_hse_val}
            </td>
          </tr>

          <tr>
            <td colSpan="5">{t("floodreport.reportdata27")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_damaged_house != null &&
                dailyReport.dr_damaged_house.length > 0 &&
                dailyReport.dr_damaged_house[0].estd_pub_prty_val_today}
            </td>
            <td colSpan="6">
              {dailyReport != null &&
                dailyReport.dr_damaged_house != null &&
                dailyReport.dr_damaged_house.length > 0 &&
                dailyReport.dr_damaged_house[0].estd_pub_prty_val}
            </td>
          </tr>
          {/* <tr>
            <td class="table__data--align" rowSpan="1">
              <span>{t("floodreport.nine")}</span>
            </td>
            <td colSpan="11">
              <b class="table__data--heading">{t("floodreport.celltitle18")}</b>
            </td>
          </tr> */}
          <tr>
            <td class="table__data--align" rowSpan="6">
              <span>{t("floodreport.nine")}</span>
            </td>
            <td colSpan="5">
              <b class="table__data--heading">
                {" "}
                {t("floodreport.celltitle18")}
              </b>
            </td>

            <td colSpan="3">
              <b class="table__data--heading">{t("floodreport.celltitle19")}</b>
            </td>
            <td colSpan="6">
              <b class="table__data--heading">{t("floodreport.celltitle20")}</b>
            </td>
          </tr>
          <tr>
            <td class="table__data--align" rowSpan="2">
              <b class="table__data--heading">{t("floodreport.celltitle21")}</b>
            </td>
            <td colSpan="4"> {t("floodreport.celltitle22")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_fishing_ground != null &&
                dailyReport.dr_fishing_ground.length > 0 &&
                dailyReport.dr_fishing_ground[0].damaged_boats_complete_curr}
            </td>
            <td colSpan="6">
              {dailyReport != null &&
                dailyReport.dr_fishing_ground != null &&
                dailyReport.dr_fishing_ground.length > 0 &&
                dailyReport.dr_fishing_ground[0]
                  .damaged_boats_complete_till_now}
            </td>
          </tr>
          <tr>
            <td colSpan="4"> {t("floodreport.celltitle23")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_fishing_ground != null &&
                dailyReport.dr_fishing_ground.length > 0 &&
                dailyReport.dr_fishing_ground[0].damaged_boats_partial_curr}
            </td>
            <td colSpan="6">
              {dailyReport != null &&
                dailyReport.dr_fishing_ground != null &&
                dailyReport.dr_fishing_ground.length > 0 &&
                dailyReport.dr_fishing_ground[0].damaged_boats_partial_till_now}
            </td>
          </tr>
          <tr>
            <td rowSpan="2">
              <b class="table__data--heading">{t("floodreport.celltitle24")}</b>
            </td>

            <td colSpan="4"> {t("floodreport.celltitle25")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_fishing_ground != null &&
                dailyReport.dr_fishing_ground.length > 0 &&
                dailyReport.dr_fishing_ground[0].damaged_net_complete_curr}
            </td>

            <td colSpan="6">
              {dailyReport != null &&
                dailyReport.dr_fishing_ground != null &&
                dailyReport.dr_fishing_ground.length > 0 &&
                dailyReport.dr_fishing_ground[0].damaged_net_complete_till_now}
            </td>
          </tr>
          <tr>
            <td colSpan="4"> {t("floodreport.celltitle26")}</td>
            <td colSpan="3">
              {" "}
              {dailyReport != null &&
                dailyReport.dr_fishing_ground != null &&
                dailyReport.dr_fishing_ground.length > 0 &&
                dailyReport.dr_fishing_ground[0].damaged_net_partial_curr}
            </td>
            <td colSpan="6">
              {dailyReport != null &&
                dailyReport.dr_fishing_ground != null &&
                dailyReport.dr_fishing_ground.length > 0 &&
                dailyReport.dr_fishing_ground[0].damaged_net_partial_till_now}
            </td>
          </tr>

          <tr>
            <td colSpan="5">{t("floodreport.reportdata64")}</td>
            <td colSpan="3">
              {" "}
              {dailyReport != null &&
                dailyReport.dr_fishing_ground != null &&
                dailyReport.dr_fishing_ground.length > 0 &&
                dailyReport.dr_fishing_ground[0]
                  .area_of_damaged_fish_egg_farm_curr}
            </td>
            <td colSpan="6">
              {dailyReport != null &&
                dailyReport.dr_fishing_ground != null &&
                dailyReport.dr_fishing_ground.length > 0 &&
                dailyReport.dr_fishing_ground[0]
                  .area_of_damaged_fish_egg_farm_till_now}
            </td>
          </tr>

          <tr>
            <td rowSpan="4" class="table__data--align">
              <span>{t("floodreport.ten")}</span>
            </td>
            <td colSpan="12" class="table__data--align">
              {" "}
              <b class="table__data--heading">{t("floodreport.celltitle27")}</b>
            </td>
          </tr>
          <tr>
            <td colSpan="5" class="table__data--align"></td>

            <td colSpan="3">
              <b class="table__data--heading">{t("floodreport.celltitle28")}</b>
            </td>
            <td colSpan="6">
              <b class="table__data--heading">{t("floodreport.celltitle29")}</b>
            </td>
          </tr>
          <tr>
            <td colSpan="5">{t("floodreport.reportdata28")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_total_death != null &&
                dailyReport.dr_total_death.length > 0 &&
                dailyReport.dr_total_death[0].human_deaths_curr}
            </td>
            <td colSpan="5">
              {dailyReport != null &&
                dailyReport.dr_total_death != null &&
                dailyReport.dr_total_death.length > 0 &&
                dailyReport.dr_total_death[0].human_deaths_till_now}
            </td>
          </tr>

          <tr>
            <td colSpan="5">{t("floodreport.reportdata29")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_total_death != null &&
                dailyReport.dr_total_death.length > 0 &&
                dailyReport.dr_total_death[0].animal_deaths_curr}
            </td>
            <td colSpan="5">
              {dailyReport != null &&
                dailyReport.dr_total_death != null &&
                dailyReport.dr_total_death.length > 0 &&
                dailyReport.dr_total_death[0].animal_deaths_till_now}
            </td>
          </tr>

          <tr>
            <td class="table__data--align" rowSpan="4">
              <span> {t("floodreport.eleven")}</span>
            </td>
            <td colSpan="11">
              <b class="table__data--heading">{t("floodreport.celltitle30")}</b>
            </td>
          </tr>
          <tr>
            <td colSpan="7">{t("floodreport.reportdata30")}</td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_total_boats != null &&
                dailyReport.dr_total_boats.length > 0 &&
                dailyReport.dr_total_boats[0].mtr_bot}
            </td>
          </tr>
          <tr>
            <td colSpan="7">{t("floodreport.reportdata31")}</td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_total_boats != null &&
                dailyReport.dr_total_boats.length > 0 &&
                dailyReport.dr_total_boats[0].govt_bot}
            </td>
          </tr>
          <tr>
            <td colSpan="7">{t("floodreport.reportdata32")}</td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_total_boats != null &&
                dailyReport.dr_total_boats.length > 0 &&
                dailyReport.dr_total_boats[0].pvt_bot}
            </td>
          </tr>

          <tr>
            <td class="table__data--align" rowSpan="3">
              <span>{t("floodreport.twelve")}</span>
            </td>
            <td colSpan="7">{t("floodreport.reportdata33")} </td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_population_evacuation != null &&
                dailyReport.dr_population_evacuation.length > 0 &&
                dailyReport.dr_population_evacuation[0].totl_evctd_till_ystd}
            </td>
          </tr>
          <tr>
            <td colSpan="7"> {t("floodreport.reportdata34")} </td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_population_evacuation != null &&
                dailyReport.dr_population_evacuation.length > 0 &&
                dailyReport.dr_population_evacuation[0].totl_evctd_today}
            </td>
          </tr>
          <tr>
            <td colSpan="7">{t("floodreport.reportdata35")}</td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_population_evacuation != null &&
                dailyReport.dr_population_evacuation.length > 0 &&
                dailyReport.dr_population_evacuation[0].totl_evctd_till_ystd +
                  dailyReport.dr_population_evacuation[0].totl_evctd_today}
            </td>
          </tr>
          <tr>
            <td class="table__data--align" rowSpan="8">
              <span>{t("floodreport.thirteen")}</span>
            </td>
            <td colSpan="7">{t("floodreport.reportdata36")} </td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_flood_relief != null &&
                dailyReport.dr_flood_relief.length > 0 &&
                dailyReport.dr_flood_relief[0].totl_cmp_opn}
            </td>
          </tr>
          <tr>
            <td colSpan="7">{t("floodreport.reportdata37")}</td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_flood_relief != null &&
                dailyReport.dr_flood_relief.length > 0 &&
                dailyReport.dr_flood_relief[0].totl_cmp_cls}
            </td>
          </tr>
          <tr>
            <td colSpan="7">{t("floodreport.reportdata38")} </td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_flood_relief != null &&
                dailyReport.dr_flood_relief.length > 0 &&
                dailyReport.dr_flood_relief[0].totl_cmp_opn_tdy}
            </td>
          </tr>
          <tr>
            <td colSpan="7">{t("floodreport.reportdata39")}</td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_flood_relief != null &&
                dailyReport.dr_flood_relief.length > 0 &&
                dailyReport.dr_flood_relief[0].totl_ppl_rgtd_til_ystd}
            </td>
          </tr>
          <tr>
            <td colSpan="7">{t("floodreport.reportdata40")}</td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_flood_relief != null &&
                dailyReport.dr_flood_relief.length > 0 &&
                dailyReport.dr_flood_relief[0].new_ppl_rgtd_tdy}
            </td>
          </tr>
          <tr>
            <td colSpan="7">{t("floodreport.reportdata41")}</td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_flood_relief != null &&
                dailyReport.dr_flood_relief.length > 0 &&
                dailyReport.dr_flood_relief[0].totl_ppl_rgtd}
            </td>
          </tr>

          <tr>
            <td colSpan="7">{t("floodreport.reportdata42")}</td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_flood_relief != null &&
                dailyReport.dr_flood_relief.length > 0 &&
                dailyReport.dr_flood_relief[0].totl_ppl_gne_bck_fr_cmp}
            </td>
          </tr>

          <tr>
            <td colSpan="7">{t("floodreport.reportdata43")}</td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_flood_relief != null &&
                dailyReport.dr_flood_relief.length > 0 &&
                dailyReport.dr_flood_relief[0].ppl_rmng_in_cmp}
            </td>
          </tr>

          <tr>
            <td class="table__data--align" rowSpan="8">
              <span>{t("floodreport.fourteen")}</span>
            </td>
            <td colSpan="7">{t("floodreport.reportdata44")}</td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_community_kitchen != null &&
                dailyReport.dr_community_kitchen.length > 0 &&
                dailyReport.dr_community_kitchen[0].totl_cmty_ktch_open}
            </td>
          </tr>
          <tr>
            <td colSpan="7">{t("floodreport.reportdata45")} </td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_community_kitchen != null &&
                dailyReport.dr_community_kitchen.length > 0 &&
                dailyReport.dr_community_kitchen[0].totl_cmty_ktch_close}
            </td>
          </tr>
          <tr>
            <td colSpan="7">{t("floodreport.reportdata46")} </td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_community_kitchen != null &&
                dailyReport.dr_community_kitchen.length > 0 &&
                dailyReport.dr_community_kitchen[0].totl_cmty_ktch_open_tdy}
            </td>
          </tr>
          <tr>
            <td colSpan="11">
              <b class="table__data--heading">
                {t("floodreport.reportdata47")}
              </b>
            </td>
          </tr>

          <tr>
            <td colSpan="7"> {t("floodreport.reportdata48")}</td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_community_kitchen != null &&
                dailyReport.dr_community_kitchen.length > 0 &&
                dailyReport.dr_community_kitchen[0].num_of_ppl_etng_lun_tdy}
            </td>
          </tr>

          <tr>
            <td colSpan="7"> {t("floodreport.reportdata49")}</td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_community_kitchen != null &&
                dailyReport.dr_community_kitchen.length > 0 &&
                dailyReport.dr_community_kitchen[0].num_of_ppl_etng_dinr_tngt}
            </td>
          </tr>
          <tr>
            <td colSpan="7"> {t("floodreport.reportdata50")}</td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_community_kitchen != null &&
                dailyReport.dr_community_kitchen.length > 0 &&
                dailyReport.dr_community_kitchen[0].totl_num_of_ppl_etng_tdy}
            </td>
          </tr>

          <tr>
            <td colSpan="7">
              <b class="table__data--heading">
                {t("floodreport.reportdata51")}
              </b>
            </td>
            <td colSpan="8">
              {dailyReport != null &&
                dailyReport.dr_community_kitchen != null &&
                dailyReport.dr_community_kitchen.length > 0 &&
                dailyReport.dr_community_kitchen[0].totl_num_of_mls_prvd_til_dt}
            </td>
          </tr>

          <tr class="table__data--align">
            <td class="table__data--align" rowSpan="5">
              <span> {t("floodreport.fifteen")}</span>
            </td>
            <td colSpan="5">
              <b class="table__data--heading">
                {" "}
                {t("floodreport.celltitle33")}{" "}
              </b>
            </td>
            <td class="table__data--align" colSpan="3">
              <b class="table__data--heading">{t("floodreport.celltitle31")}</b>
            </td>
            <td class="table__data--align" colSpan="4">
              <b class="table__data--heading">{t("floodreport.celltitle32")}</b>
            </td>
          </tr>

          <tr>
            <td colSpan="5"> {t("floodreport.reportdata52")} </td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_health_center != null &&
                dailyReport.dr_health_center.length > 0 &&
                dailyReport.dr_health_center[0].num_of_hlt_ctr_rng_dly}
            </td>
            <td colSpan="4">
              {dailyReport != null &&
                dailyReport.dr_health_center != null &&
                dailyReport.dr_health_center.length > 0 &&
                dailyReport.dr_health_center[0].num_of_hlt_ctr_rng_til_now}
            </td>
          </tr>
          <tr>
            <td colSpan="5">{t("floodreport.reportdata53")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_health_center != null &&
                dailyReport.dr_health_center.length > 0 &&
                dailyReport.dr_health_center[0].num_of_ppl_trtd_dly}
            </td>
            <td colSpan="4">
              {dailyReport != null &&
                dailyReport.dr_health_center != null &&
                dailyReport.dr_health_center.length > 0 &&
                dailyReport.dr_health_center[0].num_of_ppl_trtd_til_now}
            </td>
          </tr>
          <tr>
            <td colSpan="5">{t("floodreport.reportdata54")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_health_center != null &&
                dailyReport.dr_health_center.length > 0 &&
                dailyReport.dr_health_center[0].num_of_hlgn_tlbt_dist_dly}
            </td>
            <td colSpan="4">
              {dailyReport != null &&
                dailyReport.dr_health_center != null &&
                dailyReport.dr_health_center.length > 0 &&
                dailyReport.dr_health_center[0].num_of_hlgn_tlbt_dist_til_now}
            </td>
          </tr>
          <tr>
            <td colSpan="5">{t("floodreport.reportdata55")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_health_center != null &&
                dailyReport.dr_health_center.length > 0 &&
                dailyReport.dr_health_center[0].amt_of_blhng_pdr_sprk_dly}
            </td>
            <td colSpan="4">
              {dailyReport != null &&
                dailyReport.dr_health_center != null &&
                dailyReport.dr_health_center.length > 0 &&
                dailyReport.dr_health_center[0].amt_of_blhng_pdr_sprk_til_now}
            </td>
          </tr>

          <tr>
            <td class="table__data--align" rowSpan="2">
              <span> {t("floodreport.sixteen")}</span>
            </td>
            <td colSpan="5">{t("floodreport.reportdata56")}</td>
            <td class="" colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_health_center != null &&
                dailyReport.dr_health_center.length > 0 &&
                dailyReport.dr_health_center[0].number_of_animal_camps_daily}
            </td>
            <td class="" colSpan="4">
              {dailyReport != null &&
                dailyReport.dr_health_center != null &&
                dailyReport.dr_health_center.length > 0 &&
                dailyReport.dr_health_center[0].num_of_anml_cmp_til_now}
            </td>
          </tr>
          <tr>
            <td colSpan="5">{t("floodreport.reportdata57")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_health_center != null &&
                dailyReport.dr_health_center.length > 0 &&
                dailyReport.dr_health_center[0].num_of_anml_trtd_dly}
            </td>
            <td colSpan="4">
              {dailyReport != null &&
                dailyReport.dr_health_center != null &&
                dailyReport.dr_health_center.length > 0 &&
                dailyReport.dr_health_center[0].num_of_anml_trtd_til_now}
            </td>
          </tr>

          <tr>
            <td class="table__data--align" colSpan="6">
              <b class="table__data--heading">{t("floodreport.celltitle34")}</b>
            </td>
            <td class="table__data--align" colSpan="3">
              <b class="table__data--heading">
                {" "}
                {t("floodreport.celltitle35")}
              </b>
            </td>
            <td class="table__data--align" colSpan="4">
              <b class="table__data--heading">{t("floodreport.celltitle36")}</b>
            </td>
          </tr>

          <tr>
            <td class="table__data--align" rowSpan="1">
              <span>{t("floodreport.sevteen")}</span>
            </td>
            <td colSpan="5">{t("floodreport.reportdata58")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_relief_distribution != null &&
                dailyReport.dr_relief_distribution.length > 0 &&
                dailyReport.dr_relief_distribution[0].amt_of_gr_rcmdt_tdy}
            </td>
            <td colSpan="5">
              {dailyReport != null &&
                dailyReport.dr_relief_distribution != null &&
                dailyReport.dr_relief_distribution.length > 0 &&
                dailyReport.dr_relief_distribution[0].amt_of_gr_rcmdt_til_now}
            </td>
          </tr>
          <tr>
            <td class="table__data--align" rowSpan="1">
              <span> {t("floodreport.eighteen")}</span>
            </td>
            <td colSpan="5">{t("floodreport.reportdata59")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_relief_distribution != null &&
                dailyReport.dr_relief_distribution.length > 0 &&
                dailyReport.dr_relief_distribution[0]
                  .num_of_dry_rtn_pkt_per_fam_tdy}
            </td>
            <td colSpan="5">
              {dailyReport != null &&
                dailyReport.dr_relief_distribution != null &&
                dailyReport.dr_relief_distribution.length > 0 &&
                dailyReport.dr_relief_distribution[0]
                  .num_of_dry_rtn_pkt_per_fam_til_now}
            </td>
          </tr>
          <tr>
            <td class="table__data--align" rowSpan="1">
              <span> {t("floodreport.nineteen")}</span>
            </td>
            <td colSpan="5">{t("floodreport.reportdata60")}</td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_relief_distribution != null &&
                dailyReport.dr_relief_distribution.length > 0 &&
                dailyReport.dr_relief_distribution[0].num_of_fd_pkt_tdy}
            </td>
            <td colSpan="5">
              {dailyReport != null &&
                dailyReport.dr_relief_distribution != null &&
                dailyReport.dr_relief_distribution.length > 0 &&
                dailyReport.dr_relief_distribution[0].num_of_fd_pkt_til_now}
            </td>
          </tr>
          <tr>
            <td class="table__data--align" rowSpan="1">
              <span>{t("floodreport.twenty")}</span>
            </td>
            <td colSpan="5"> {t("floodreport.reportdata61")} </td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_relief_distribution != null &&
                dailyReport.dr_relief_distribution.length > 0 &&
                dailyReport.dr_relief_distribution[0].num_of_poly_shts_dist_tdy}
            </td>
            <td colSpan="5">
              {dailyReport != null &&
                dailyReport.dr_relief_distribution != null &&
                dailyReport.dr_relief_distribution.length > 0 &&
                dailyReport.dr_relief_distribution[0]
                  .num_of_poly_shts_dist_til_now}
            </td>
          </tr>
          <tr>
            <td class="table__data--align" rowSpan="1">
              <span>{t("floodreport.twentyone")}</span>
            </td>
            <td colSpan="5"> {t("floodreport.reportdata62")} </td>
            <td colSpan="3">
              {dailyReport != null &&
                dailyReport.dr_relief_distribution != null &&
                dailyReport.dr_relief_distribution.length > 0 &&
                dailyReport.dr_relief_distribution[0].otr_ingts_tdy}
            </td>
            <td colSpan="5">
              {dailyReport != null &&
                dailyReport.dr_relief_distribution != null &&
                dailyReport.dr_relief_distribution.length > 0 &&
                dailyReport.dr_relief_distribution[0].otr_ingts_til_now}
            </td>
          </tr>
        </table>
        {/* <div className="table__floodreport--footer">
        <div>District Magistrate</div>
        <div className="table__floodreport__dist">
          <span id="selected_dist_name">[ {selectedDistrict} ]</span>
        </div>
      </div> */}
        <div></div>
      </div>
    </div>
  );
};

export default PartiallyAffectedReportTable;
