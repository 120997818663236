import React, { useEffect, useState } from 'react';
import './WeatherDataTable.css';
import axios from 'axios';
import ToggleViewBmsk from './ToggleView/ToggleViewBmsk';
import DoughnutChart from './DoughnutChart';
import HalfDoughnutChart from './HalfDoughnutChart';
import HorizontalBarChart from './HorizontalBarChart';
import { useTranslation } from "react-i18next";
import moment from 'moment';

const WeatherDataTable = ({ affectedDistrictData, affectedBlockData, affectedNagarNigamData, affectedPanchayatData, fromDate, toDate }) => {
    const [weatherApiData, setWeatherApiData] = useState([]);
    const [activeTabBmsk, setActiveTabBmsk] = useState("Charts");  // Set default to "Charts"
    const { t } = useTranslation();

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const todayDate = formatDate(new Date());

    useEffect(() => {
        const fetchWeatherData = async () => {
            try {
                const response = await axios.get(`https://www.mausamsewa.bihar.gov.in/BMSK_API/api/DAILYFORECAST/${todayDate}`, {
                    withCredentials: true ,
                    headers: {
                        Authorization: 'Bearer '+localStorage.getItem("auth_token"),
                        'Content-Type': 'application/json',
                    },
                  });
                  
                setWeatherApiData(response.data);
            } catch (error) {
                console.error('Error fetching weather data:', error);
            }
        };

        fetchWeatherData();
    }, [todayDate]);

    const layoutData = [
        {
            x: 0,
            y: 0,
            width: 6,
            height: 4,
            id: 7,
            component: (
                <DoughnutChart
                    title={t("flooddisaster.doughnutchart1")}
                    dataval={affectedDistrictData}
                    startDateStr={moment(fromDate).format("YYYY-MM-DD")}
                    endDateStr={moment(toDate).format("YYYY-MM-DD")}
                    partial={true}
                    showViewIcon={true}
                />
            ),
        },
        {
            x: 6,
            y: 0,
            width: 6,
            height: 4,
            id: 8,
            component: (
                <HalfDoughnutChart
                    startDateStr={moment(fromDate).format("YYYY-MM-DD")}
                    endDateStr={moment(toDate).format("YYYY-MM-DD")}
                    title={t("flooddisaster.doughnutchart2")}
                    dataval={affectedBlockData}
                    partial={true}
                    showViewIcon={true}
                />
            ),
        },
        {
            x: 0,
            y: 4,
            width: 6,
            height: 5,
            id: 10,
            component: (
                <HorizontalBarChart
                    title={t("flooddisaster.doughnutchart3")}
                    dataval={affectedNagarNigamData}
                    showAffectedCount={true}
                />
            ),
        },
        {
            x: 6,
            y: 4,
            width: 6,
            height: 5,
            id: 12,
            component: (
                <HorizontalBarChart
                    title={t("flooddisaster.doughnutchart4")}
                    dataval={affectedPanchayatData}
                    showAffectedCount={true}
                />
            ),
        },
    ];

    return (
        <div>
            <div className={activeTabBmsk === "Charts" ? "header-container" : "header-container-chart"}>
                {activeTabBmsk === "BMSK" ? (
                    <span className='p-data'>Data received at 10:30 AM on {todayDate}</span>
                ) : null}
                <div className="toggle-container">
                    <ToggleViewBmsk
                        className={activeTabBmsk === "Charts" ? "button-Charts" : "button-BMSK"}
                        setActiveTabBmsk={setActiveTabBmsk}
                        activeTabBmsk={activeTabBmsk}
                    />
                </div>
            </div>

            <div className="charts-grid-wrapper">
                {activeTabBmsk === "Charts" ? (
                    <div className="grid-stack grid-stack-instance-1 mt-1">
                        {layoutData.map((gridItem) => (
                            <div
                                className="grid-stack-item"
                                gs-x={gridItem.x}
                                gs-y={gridItem.y}
                                gs-w={gridItem.width}
                                gs-h={gridItem.height}
                                key={gridItem.id}
                            >
                                <div className="grid-stack-item-content">
                                    {gridItem.component}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>S NO</th>
                                    <th>District</th>
                                    <th>Rainfall (mm)</th>
                                    <th>Temperature (°C)</th>
                                    <th>Humidity (%)</th>
                                    <th>Wind Speed (m/s)</th>
                                    <th>Wind Direction (°)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {weatherApiData.map((row, index) => (
                                    <tr key={index} className={index % 2 === 0 ? 'dark-blue-row' : 'light-blue-row'}>
                                        <td>{index + 1}</td>
                                        <td>{row.DISTRICT}</td>
                                        <td>{row.RAINFALL}</td>
                                        <td>{row.TEMP_MIN}-{row.TEMP_MAX}</td>
                                        <td>{row.HUMIDITY}</td>
                                        <td>{row.WIND_SPEED}</td>
                                        <td>{row.WIND_DIRECTION}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default WeatherDataTable;
