import React, { useEffect } from "react";
import GridItem from "../../../../Components/UiComponents/GridItem/GridItem";
import { GridStack } from "gridstack";
import DMSHeader from "../../DMSHeader/DMSHeader";
import { useTranslation } from "react-i18next";
import Form1Table from "./Form1Table/Form1Table";

const StateFloodReport = () => {
  return (
    <div>
      <div className="widget-container">
        <DMSHeader title="Consolidated Report" />
      </div>
      <div className="widget-container p-1">
        <Form1Table />
      </div>
    </div>
  );
};

export default StateFloodReport;
