import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ExpandTable from "../TableComponents/ExpandTable/ExpandTable";
import ExportTableData from "../TableComponents/ExportTableData/ExportTableData";
import FilterBtnGroup from "../TableComponents/FilterBtnGroup/FilterBtnGroup";
import LMSTotalSales from "../TableComponents/LMSTotalSales/LMSTotalSales";
import ResetTableData from "../TableComponents/ResetTableData/ResetTableData";
import SearchBox from "../TableComponents/SearchBox/SearchBox";
import TableTitle from "../TableComponents/TableTitle/TableTitle";
import "./TableHeader.css";
import ColumnFilter from "../../../../Pages/DMS/Common/ColumnFilter/ColumnFilter";
import { ColumnFilterIcon } from "../../../IconComponents";

const TableHeader = (props) => {
  const [showModal, setShowModal] = useState(false);

  const {
    title,
    searchValue,
    onSearchHandler,
    showExport = true,
    showReset = true,
    onExpand,
    showFilter,
    addNewHandler,
    addBlockHandler,
    addUnBlockHandler,
    showSalesTotal,
    showProcurementTotal,
    addSalesTotalValue,
    showSearch = true,
    text,
    status,
    id,
    addButtonLabel,
    addButtonLabelBlock,
    addButtonLabelUnBlock = "Unblock",
    showFilterColumn,
    toggleHideColumn,
    allColumns
  } = props;
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleOpenModal = () => {
    setShowModal(true);
  };
  return (
    <div className="iccc__table-header">
      <div className="d-flex align-items-center">
        {addNewHandler && (
          <div className="table_header__addnewbutton">
            <Button size="btn_sm" variant="primary" onClick={addNewHandler}>
              {addButtonLabel}
            </Button>
          </div>
        )}
        {title && <TableTitle title={title} />}
      </div>
      {showFilter && (
        <div className="filter__Button">
          <FilterBtnGroup {...props} />
        </div>
      )}
      <div className="table-search d-flex gap-2">
        {showSearch && (
          <SearchBox
            searchValue={searchValue}
            onSearchHandler={onSearchHandler}
          />
        )}
        {showReset && <ResetTableData {...props} />}

        {status === "Blocked" ? (
          <div className="table_header__addblockbutton">
            <Button size="sm" variant="success" onClick={addUnBlockHandler}>
              {addButtonLabelUnBlock}
            </Button>
          </div>
        ) : (
          id && (
            <div className="table_header__addblockbutton">
              {addBlockHandler && (
                <Button size="sm" variant="success" onClick={addBlockHandler}>
                  {addButtonLabelBlock}
                </Button>
              )}
            </div>
          )
        )}

        {showSalesTotal && (
          <LMSTotalSales addSalesTotalValue={addSalesTotalValue} />
        )}
        {showExport && <ExportTableData {...props} />}
        {onExpand && <ExpandTable {...props} />}
        {showFilterColumn && (
          <>
            <ColumnFilterIcon
              onClick={handleOpenModal}
              width="2rem"
              height="2rem"
              className="cursor-pointer"
            />
            <ColumnFilter
              allColumns={allColumns}
              toggleHideColumn={toggleHideColumn}
              showModal={showModal}
              handleClose={handleCloseModal}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TableHeader;
