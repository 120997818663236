import React, { useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { GridStack } from "gridstack";

import GridItem from "../../../Components/UiComponents/GridItem/GridItem";
import ResourceTable from "./ResourceList/ResourceTable/ResourceTable";
import DMSHeader from "../DMSHeader/DMSHeader";
import { useTranslation } from 'react-i18next';

const ResourceManagement = (props) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    GridStack.init();
  }, []);
  const location = useLocation();
  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: (
        <DMSHeader
          title={t('resource_management_header')}
          fileName="ResourceManagement"
          showmenu={true}
          showImportDropDownLabel={false}
          showdownload={true}
        />
      ),
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height: 12,
      id: 2,
      component: <ResourceTable locationInstance={location.state} />,
    },
  ];

  return (
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      <GridItem layoutData={layoutData} />
    </div>
  );
};

export default ResourceManagement;
