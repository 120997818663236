export const misDistReportConfig = {
  colKey1: "",
  colKey2: "",
  colKey3: "block_name",
  colKey4: "totl_grm_pnchyt",
  colKey5: "nos_vilge_aftd",
  colKey6: "totl_ngr_pnchyt",
  colKey7: "totl_mucpl_corp",
  colKey8: "totl_ngr_parsad",
  colKey9: "nos_wrd_aftd",
  colKey10: "totl_aftd",
  colKey11: "aftd_pop_rural_today",
  colKey12: "aftd_pop_urban_today",
  colKey13: "aftd_pop_totl_today",
  colKey14: "aftd_pop_rural_ystd",
  colKey15: "aftd_pop_urban_ystd",
  colKey16: "aftd_pop_totl_ystd",
  colKey17: "aftd_pop_rural",
  colKey18: "aftd_pop_urban",
  colKey19: "totl_pop_aftd",
  colKey20: "aftd_fam_rural_today",
  colKey21: "aftd_fam_urban_today",
  colKey22: "aftd_fam_totl_today",
  colKey23: "aftd_fam_rural_ystd",
  colKey24: "aftd_fam_urban_ystd",
  colKey25: "aftd_fam_totl_ystd",
  colKey26: "aftd_fam_rural",
  colKey27: "aftd_fam_urban",
  colKey28: "totl_fam_aftd",
  colKey29: "aftd_anml_rural_today",
  colKey30: "aftd_anml_urban_today",
  colKey31: "aftd_anml_totl_today",
  colKey32: "aftd_anml_rural_ystd",
  colKey33: "aftd_anml_urban_ystd",
  colKey34: "aftd_anml_totl_ystd",
  colKey35: "aftd_anml_rural",
  colKey36: "aftd_anml_urban",
  colKey37: "totl_anml_aftd",
  colKey38: "river_name",
  colKey39: "affected_blocks_count",
  colKey40: "affected_gram_panchayats",
  colKey41: "affected_municipal_bodies",
  colKey42: "affected_villages",
  colKey43: "affected_wards",
  colKey44: "affected_population",
  colKey45: "agricultural_area_affected_curr",
  colKey46: "agricultural_area_affected_till_now",
  colKey47: "non_agricultural_area_affected_curr",
  colKey48: "non_agricultural_area_affected_till_now",
  colKey49: "damaged_crop_area_affected_curr",
  colKey50: "damaged_crop_area_affected_till_now",
  colKey51: "estimated_value_of_damaged_crops_affected_curr",
  colKey52: "estimated_value_of_damaged_crops_affected_till_now",
  colKey53: "ktha_cmplt_today",
  colKey54: "ktha_cmplt",
  colKey55: "ktha_prtl_today",
  colKey56: "ktha_prtl",
  colKey57: "crct_cmplt_today",
  colKey58: "crct_cmplt",
  colKey59: "crct_prtl_today",
  colKey60: "crct_prtl",
  colKey61: "ctg_hut_today",
  colKey62: "ctg_hut",
  colKey63: "anml_shd_today",
  colKey64: "anml_shd",
  colKey65: "estd_hse_val_today",
  colKey66: "estd_hse_val",
  colKey67: "estd_pub_prty_val_today",
  colKey68: "estd_pub_prty_val",
  colKey69: "damaged_boats_complete_curr",
  colKey70: "damaged_boats_complete_till_now",
  colKey71: "damaged_boats_partial_curr",
  colKey72: "damaged_boats_partial_till_now",
  colKey73: "damaged_net_complete_curr",
  colKey74: "damaged_net_complete_till_now",
  colKey75: "damaged_net_partial_curr",
  colKey76: "damaged_net_partial_till_now",
  colKey77: "area_of_damaged_fish_egg_farm_curr",
  colKey78: "area_of_damaged_fish_egg_farm_till_now",
  colKey79: "human_deaths_curr",
  colKey80: "human_deaths_till_now",
  colKey81: "animal_deaths_curr",
  colKey82: "animal_deaths_till_now",
  colKey83: "mtr_bot",
  colKey84: "govt_bot",
  colKey85: "pvt_bot",
  colKey86: "totl_evctd_till_ystd",
  colKey87: "totl_evctd_today",
  colKey88: "totl_evctd_till_now",
  colKey89: "totl_cmp_opn",
  colKey90: "totl_cmp_cls",
  colKey91: "totl_cmp_opn_tdy",
  colKey92: "totl_ppl_rgtd_til_ystd",
  colKey93: "new_ppl_rgtd_tdy",
  colKey94: "totl_ppl_rgtd",
  colKey95: "totl_ppl_gne_bck_fr_cmp",
  colKey96: "ppl_rmng_in_cmp",
  colKey97: "totl_cmty_ktch_open",
  colKey98: "totl_cmty_ktch_close",
  colKey99: "totl_cmty_ktch_open_tdy",
  colKey100: "num_of_ppl_etng_lun_tdy",
  colKey101: "num_of_ppl_etng_dinr_tngt",
  colKey102: "totl_num_of_ppl_etng_tdy",
  colKey103: "totl_num_of_mls_prvd_til_dt",
  colKey104: "num_of_hlt_ctr_rng_dly",
  colKey105: "num_of_hlt_ctr_rng_til_now",
  colKey106: "num_of_ppl_trtd_dly",
  colKey107: "num_of_ppl_trtd_til_now",
  colKey108: "num_of_hlgn_tlbt_dist_dly",
  colKey109: "num_of_hlgn_tlbt_dist_til_now",
  colKey110: "amt_of_blhng_pdr_sprk_dly",
  colKey111: "amt_of_blhng_pdr_sprk_til_now",
  colKey112: "number_of_animal_camps_daily",
  colKey113: "num_of_anml_cmp_til_now",
  colKey114: "num_of_anml_trtd_dly",
  colKey115: "num_of_anml_trtd_til_now",
  colKey116: "amt_of_gr_rcmdt_tdy",
  colKey117: "amt_of_gr_rcmdt_til_now",
  colKey118: "num_of_dry_rtn_pkt_per_fam_tdy",
  colKey119: "num_of_dry_rtn_pkt_per_fam_til_now",
  colKey120: "num_of_fd_pkt_tdy",
  colKey121: "num_of_fd_pkt_til_now",
  colKey122: "num_of_poly_shts_dist_tdy",
  colKey123: "num_of_poly_shts_dist_til_now",
  colKey124: "otr_ingts_tdy",
  colKey125: "otr_ingts_til_now",
};

export const misColumns = Object.entries(misDistReportConfig).map(
  ([key, value], index) => ({
    Header: `misdistfloodreport.headercol${index + 1}`,
    accessor: value,
    disableSortBy: true,
    showInitially: true,
  })
);
