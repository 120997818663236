import * as React from "react";
const SvgColumnFilterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 50 40"
    {...props}
  >
    <rect width={50} height={40} fill="#17A3A0" rx={4} />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M27.25 12.222C27.25 10.995 26.243 10 25 10s-2.25.995-2.25 2.222m4.5 0c0 1.228-1.007 2.222-2.25 2.222s-2.25-.995-2.25-2.222m4.5 0H34m-11.25 0H16M29.5 20c0 1.227 1.007 2.222 2.25 2.222S34 21.227 34 20c0-1.227-1.007-2.222-2.25-2.222S29.5 18.773 29.5 20Zm0 0H16m4.5 7.778c0-1.228-1.007-2.222-2.25-2.222S16 26.55 16 27.778C16 29.005 17.007 30 18.25 30s2.25-.995 2.25-2.222Zm0 0H34"
    />
  </svg>
);
export default SvgColumnFilterIcon;
