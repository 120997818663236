import React, { useEffect, useState, useRef, useCallback } from "react";
import Map from "ol/Map";
import View from "ol/View";
import GeoJSON from "ol/format/GeoJSON";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import "ol/ol.css";
import OSM from "ol/source/OSM";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import XYZ from "ol/source/XYZ";
import VectorSource from "ol/source/Vector";
import { Projection } from "ol/proj";
import ImageWMS from "ol/source/ImageWMS";
import Image from "ol/layer/Image";
import {
  postDataWithBodyFrGIS,
  getWFSServiceData,
  getWFSLayer,
} from "../../../Services/Services";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  ArrowsFullscreen,
  Fullscreen,
  InfoCircleFill,
} from "react-bootstrap-icons";
import Overlay from "ol/Overlay.js";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import { toLonLat } from "ol/proj.js";
import { toStringHDMS } from "ol/coordinate.js";

const OpenLayerSource = (props) => {
  const [map, setMap] = useState(null);
  const [mapdependency, setMapdependency] = useState(1);
  const [incidentdependency, setIncidentdependency] = useState(1);
  const [incident, setIncident] = useState(null);
  const [incidentbasic, setIncidentbasic] = useState(null);
  const [incidentregion, setIncidentregion] = useState(null);
  const [incidentgis, setIncidentgis] = useState(null);
  const [show, setShow] = useState(false);
  const [incidentid, setIncidentid] = useState("");
  const [startScheduler, setStartScheduler] = useState(false);
  const [mapwidth, setMapwidth] = useState("col-12 g-1");
  const [infoWidth, setInfoWidth] = useState("col-0 g-1");
  const [infoStyle, setInfoStyle] = useState("none");
  const [regionView, setRegionView] = useState("78vh");
  const [eventlayer, setEventlayer] = useState(null);
  const target = useRef(null);
  //console.log(props);
  const center = props.center;
  const zoom = props.zoom;
  const gisserverurl = process.env.REACT_APP_GEO_SERVER;
  const handle = useFullScreenHandle();
  const [content, setContent] = useState(null);
  const [overlay, setOverlay] = useState(null);
  var defprojection = new Projection({
    code: "EPSG:4326",
    units: "degrees",
    axisOrientation: "neu",
  });
  const view = new View({
    center: [center.lng, center.lat],
    projection: defprojection,
    zoom: zoom,
  });
  const sourceLayer = new TileLayer({
    source: new XYZ({
      url: "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
      maxZoom: 18,
    }),
  });
  const vectsource = new VectorSource({ wrapX: false });
  const vector = new VectorLayer({
    source: vectsource,
  });
  const reportChange = useCallback(
    (state, handle1) => {
      console.log("Screen 1 went to", state, handle1);
      if (state) {
        setRegionView("92vh");
      } else {
        setRegionView("78vh");
      }
    },
    [handle]
  );

  useEffect(() => {
    initMap();
  }, []);

  useEffect(() => {
    loadcurrentincident();
  }, [mapdependency]);

  useEffect(() => {
    if (incidentgis) {
      loadincidenttomap();
    }
  }, [incidentdependency]);

  useEffect(() => {
    console.log("Staring Scheduler >>", new Date());
    if (startScheduler) {
      showincident();
      setInterval(loadcurrentincident, 15000);
    }
  }, [startScheduler]);
  useEffect(() => {
    console.log("event layer setup");
    if (map != null) {
      enableclickevent();
    }
  }, [eventlayer]);

  const loadcurrentincident = async () => {
    const activeincidents = await postDataWithBodyFrGIS(
      "/getData/getAllincidents",
      {},
      {}
    );
    if (activeincidents["data"] && activeincidents["data"][0]) {
      const activeinst = activeincidents["data"][0];
      const input = { incidentid: activeinst["id"] };
      setIncidentid(activeinst["id"]);
      const incidentdetails = await postDataWithBodyFrGIS(
        "/getAllData/incidentdtls",
        input,
        {}
      );
      console.log("incidentdtls", incidentdetails);
      setIncident(incidentdetails);
      setIncidentbasic(incidentdetails.basicinfo[0]);
      let regions = incidentdetails["regions"];
      if (
        incidentregion != null ||
        JSON.stringify(regions) != JSON.stringify(incidentregion)
      ) {
        regions = checkIncidentUpgrade(regions);
        console.log("Updated Region", JSON.stringify(regions));
        setIncidentregion(regions);
        let gisids = [];
        regions.forEach((element) => {
          gisids.push(element["gis_id"]);
        });
        getincidentgisdata(gisids, regions);
      }
    }
  };
  const checkIncidentUpgrade = (regions) => {
    if (incidentregion != null) {
      // console.log('Refresh Incident >>>');
      regions.forEach((newregion) => {
        let isNew = true;
        incidentregion.forEach((oldregion) => {
          if (newregion.gis_id === oldregion.gis_id) {
            isNew = false;
            if (newregion.code != oldregion.code) {
              newregion["statusupdated"] = true;
              newregion["oldstatus"] = oldregion.value;
              newregion["shortkey"] = 2;
            } else if (oldregion.statusupdated) {
              newregion["statusupdated"] = true;
              newregion["oldstatus"] = oldregion.oldstatus;
              newregion["shortkey"] = oldregion.shortkey;
            }
          }
        });
        if (isNew) {
          newregion["newregion"] = true;
          newregion["shortkey"] = 1;
        } else if (!newregion["shortkey"]) {
          newregion["shortkey"] = 3;
        }
      });
    }
    regions = regions.sort((a, b) => {
      if (a.shortkey < b.shortkey) {
        return -1;
      }
    });
    return regions;
  };
  const getincidentgisdata = async (regionids, regions) => {
    const url =
      "/geoserver/wfs?service=wfs&version=1.0.0&request=getfeature&typename=dms:panchayat&outputFormat=application/json";
    let nodestrs = regionids.join();
    let filter = "objectid in (" + nodestrs + ")";
    const encodedcqlfilter = encodeURI(filter);
    console.log("encodedcqlfilter>>>>" + encodedcqlfilter);
    const wfsdata = await getWFSServiceData(url, encodedcqlfilter);
    console.log("wfsdata", wfsdata);
    console.log("incident regions", regions);
    wfsdata.features.forEach((feature) => {
      regions.forEach((region) => {
        if (feature.properties.objectid + "" === region.gis_id) {
          Object.assign(feature.properties, region);
        }
      });
    });
    console.log("wfsdata", wfsdata);
    setIncidentgis(wfsdata);
    setIncidentdependency((prev) => prev + 1);
  };
  const loadincidenttomap = () => {
    const source = new VectorSource({
      features: new GeoJSON().readFeatures(incidentgis),
    });

    const layer = new VectorLayer({
      source: source,
      style: styleFunction,
    });
    map.addLayer(layer);
    if (!startScheduler) {
      setStartScheduler(true);
    }
  };
  const styleFunction = function (feature) {
    //console.log(feature);
    let type = "INCIDENT_REGION_NORMAL";
    let stroke = "",
      fill = "";
    incident["regions"].forEach((element) => {
      if (parseInt(element["gis_id"]) === feature.get("objectid")) {
        type = element["code"];
      }
    });
    if (type === "INCIDENT_REGION_NORMAL") {
      fill = "#93ea76";
      stroke = "#3b5e2f";
    } else if (type === "INCIDENT_REGION_WATCH") {
      fill = "#FFEC33";
      stroke = "#3b5e2f";
    } else if (type === "INCIDENT_REGION_WARN") {
      fill = "#FF9633";
      stroke = "#3b5e2f";
    } else if (type === "INCIDENT_REGION_AFFECT") {
      fill = "#FF5733";
      stroke = "#3b5e2f";
    }
    var retStyle = new Style({
      stroke: new Stroke({
        color: stroke,
        width: 2,
      }),
      fill: new Fill({
        color: fill,
      }),
    });
    return retStyle;
  };
  const showincident = () => {
    let wmslayer = new Image({
      source: new ImageWMS({
        ratio: 1,
        url: gisserverurl + "/geoserver/wms",
        params: {
          FORMAT: "image/png",
          VERSION: "1.1.1",
          STYLES: "",
          LAYERS: "dms:event",
          exceptions: "application/vnd.ogc.se_inimage",
          TIMESTAMP: new Date().getTime(),
        },
      }),
    });
    var filterParams = {};
    console.log("incidentid", incidentid);
    filterParams["CQL_FILTER"] = '"incident_id" IN (' + incidentid + ")";
    wmslayer.getSource().updateParams(filterParams);
    map.addLayer(wmslayer);
    setEventlayer(wmslayer);
    //gisconfig["resourcelayer"]["dms:event"] = wmslayer;
  };
  const initMap = () => {
    const container = document.getElementById("popup");
    let content1 = document.getElementById("popup-content");
    const closer = document.getElementById("popup-closer");
    setContent(content1);
    let overlay1 = new Overlay({
      element: container,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });
    closer.onclick = function () {
      overlay1.setPosition(undefined);
      closer.blur();
      return false;
    };

    let wmslayer = new Image({
      source: new ImageWMS({
        ratio: 1,
        url: gisserverurl + "/geoserver/wms",
        params: {
          FORMAT: "image/png",
          VERSION: "1.1.1",
          STYLES: "",
          LAYERS: "dms:state",
          exceptions: "application/vnd.ogc.se_inimage",
        },
      }),
    });
    const map = new Map({
      target: "map",
      layers: [sourceLayer, vector, wmslayer],
      overlays: [overlay1],
      view: view,
    });
    props.onmapload(map);
    setMap(map);
    setMapdependency((prev) => prev + 1);
    //addbasestatemap(map);
    setOverlay(overlay1);
  };

  const enableclickevent = () => {
    map.on("singleclick", function (evt) {
      console.log(evt.coordinate);
      const feature = map.forEachFeatureAtPixel(
        evt.pixel,
        function (feature, layer) {
          console.log("layer name", layer);
          return feature;
        }
      );
      console.log("feature", feature);
      if (feature) {
        const featuredtls = feature.getProperties();
        const hdms = toStringHDMS(toLonLat(evt.coordinate));
        let statushtml = "";
        if (featuredtls.code && featuredtls.code === "INCIDENT_REGION_WARN") {
          statushtml = `<span style= "background-color:#FF9633;color:#000;border-radius:4px;padding:0 5px 0 5px;">Warning</span>`;
        } else if (
          featuredtls.code &&
          featuredtls.code === "INCIDENT_REGION_NORMAL"
        ) {
          statushtml = `<span style= "background-color:#93ea76;color:#000;border-radius:4px;padding:0 5px 0 5px;">Normal</span>`;
        } else if (
          featuredtls.code &&
          featuredtls.code === "INCIDENT_REGION_WATCH"
        ) {
          statushtml = `<span style= "background-color:#FFEC33;color:#000;border-radius:4px;padding:0 5px 0 5px;">Watch</span>`;
        } else if (
          featuredtls.code &&
          featuredtls.code === "INCIDENT_REGION_AFFECT"
        ) {
          statushtml = `<span style= "background-color:#FF5733;color:#000;border-radius:4px;padding:0 5px 0 5px;">Affected</span>`;
        }
        content.innerHTML = `<div class="card">
                          <div class="card-body">
                          <div class="headerRegionContent">${featuredtls.name}</div>
                          <div class="row">
                          <div class="col-12">
                            <label>Block</label>
                            <div>${featuredtls.block}</div>
                          </div>
                          <div class="col-12">
                            <label>District</label>
                            <div>${featuredtls.dist}</div>
                          </div>
                          <div class="col-12">
                            <label>Status</label>
                            <div>
                              ${statushtml}
                            </div>
                          </div>
                          </div>
                          </div>
                          </div>`;
        overlay.setPosition(evt.coordinate);
      } else {
        showeventdtls(map, evt);
      }
    });
  };

  const showeventdtls = async (map, evt) => {
    let view = map.getView();
    let viewResolution = view.getResolution();
    const url = eventlayer
      .getSource()
      .getFeatureInfoUrl(evt.coordinate, viewResolution, view.getProjection(), {
        INFO_FORMAT: "application/json",
        FEATURE_COUNT: 50,
      });
    console.log(url);
    const wfsdata = await getWFSLayer(url);
    console.log(wfsdata);
    let eventids = [];
    wfsdata.features.forEach(element => {
      eventids.push({'id':element.properties.id});
    });
    const eventdetails = await postDataWithBodyFrGIS(
      "/getFeatures",
      {'event':eventids},
      {}
    );
    console.log(eventdetails);
    console.log(eventdetails.event.data.length);
    let eventdata = eventdetails.event.data && eventdetails.event.data.length>0 ? eventdetails.event.data[0]:null;
    if(eventdata){
    const location =  eventdata.Location ? JSON.parse(eventdata.Location):{};
    content.innerHTML = `<div class="card">
                          <div class="card-body">
                          <div class="headerRegionContent" style="text-align:center">Event Details</div>
                          <div class="row">
                          <div class="col-12">
                            <label>Id</label>
                            <div>${eventdata.EventId}</div>
                          </div>
                          <div class="col-12">
                            <label>Created By</label>
                            <div>${eventdata['Created By']}</div>
                          </div>
                          <div class="col-12">
                            <label>Location</label>
                            <div>
                              ${location.coordinates}
                            </div>
                          </div>
                          </div>
                          </div>
                          </div>`;
     overlay.setPosition(evt.coordinate);
    }
  };
  const toggleinfo = () => {
    if (mapwidth === "col-12 g-1") {
      setMapwidth("col-9 g-1");
      //setDivwidth('10%');
      setInfoWidth("col-3 g-1");
      setInfoStyle("block");
    } else {
      setMapwidth("col-12 g-1");
      //setDivwidth('10%');
      setInfoWidth("col-0 g-1");
      setInfoStyle("none");
    }
  };
  return (
    <>
      <button
        onClick={handle.enter}
        className="maximizebutton"
        style={{ background: "none" }}
      >
        <ArrowsFullscreen className="maxicon" />
      </button>
      <FullScreen
        handle={handle}
        className="nonfullscreen"
        onChange={reportChange}
      >
        <div className="row" style={{ height: "100%" }}>
          <div className={mapwidth}>
            <div className="mapcontrols">
              <InfoCircleFill
                className="mapcontrolicon"
                ref={target}
                onClick={() => {
                  toggleinfo();
                }}
              />
            </div>
            <div id="map" style={{ width: "100%", height: "100%" }}></div>
            <div id="popup" class="ol-popup">
              <a href="#" id="popup-closer" className="ol-popup-closer"></a>
              <div id="popup-content"></div>
            </div>
          </div>
          <div
            id="mapinfo"
            className={infoWidth}
            style={{ display: infoStyle }}
          >
            <Accordion
              defaultActiveKey="0"
              style={{ width: "95%", color: "#ffff" }}
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header>Basic Information</Accordion.Header>
                <Accordion.Body>
                  {incidentbasic && (
                    <div className="row">
                      <div className="col-12">
                        <label>Name</label>
                        <div>{incidentbasic.name}</div>
                      </div>
                      <div className="col-12">
                        <label>Category</label>
                        <div>{incidentbasic.cat}</div>
                      </div>
                      <div className="col-12">
                        <label>Time</label>
                        <div>{incidentbasic.time}</div>
                      </div>
                    </div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Affected Regions</Accordion.Header>
                <Accordion.Body
                  style={{
                    height: regionView,
                    overflow: "auto",
                    scrollbarWidth: "thin",
                  }}
                >
                  {incidentregion &&
                    incidentregion.map((region) => (
                      <Card style={{ marginBottom: "10px" }}>
                        <Card.Body>
                          <Card.Title className="headerRegionContent">
                            {region.name}
                          </Card.Title>
                          <div className="row">
                            <div className="col-12">
                              <label>Block</label>
                              <div>{region.block}</div>
                            </div>
                            <div className="col-12">
                              <label>District</label>
                              <div>{region.dist}</div>
                            </div>
                            <div className="col-12">
                              <label>Status</label>
                              <div>
                                {region.code &&
                                  region.code === "INCIDENT_REGION_WARN" && (
                                    <span
                                      style={{
                                        backgroundColor: "#FF9633",
                                        color: "#000",
                                        borderRadius: "4px",
                                        padding: "0 5px 0 5px",
                                      }}
                                    >
                                      Warning
                                    </span>
                                  )}
                                {region.code &&
                                  region.code === "INCIDENT_REGION_NORMAL" && (
                                    <span
                                      style={{
                                        backgroundColor: "#93ea76",
                                        color: "#000",
                                        borderRadius: "4px",
                                        padding: "0 5px 0 5px",
                                      }}
                                    >
                                      Normal
                                    </span>
                                  )}
                                {region.code &&
                                  region.code === "INCIDENT_REGION_WATCH" && (
                                    <span
                                      style={{
                                        backgroundColor: "#FFEC33",
                                        color: "#000",
                                        borderRadius: "4px",
                                        padding: "0 5px 0 5px",
                                      }}
                                    >
                                      Watch
                                    </span>
                                  )}
                                {region.code &&
                                  region.code === "INCIDENT_REGION_AFFECT" && (
                                    <span
                                      style={{
                                        backgroundColor: "#FF5733",
                                        color: "#000",
                                        borderRadius: "4px",
                                        padding: "0 5px 0 5px",
                                      }}
                                    >
                                      Affected
                                    </span>
                                  )}
                                {region.statusupdated && (
                                  <div className="blinking-text">
                                    Updated From {region.oldstatus}
                                  </div>
                                )}
                                {region.newregion && (
                                  <div className="blinking-text">
                                    New Region Added
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </FullScreen>
    </>
  );
};

export default OpenLayerSource;
