import * as React from "react";
const PaperClipIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 21 21"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
      d="M18.76 9.669l-8.042 8.041a5.253 5.253 0 01-7.428-7.429l8.04-8.041a3.502 3.502 0 114.953 4.953l-8.05 8.04a1.75 1.75 0 11-2.476-2.475l7.429-7.42"
    />
  </svg>
);

export default PaperClipIcon;
