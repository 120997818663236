import * as yup from "yup";

export const schema = [
  yup.object({
    name: yup
      .string()
      .required("Please provide the name")
      .max(50, "Name cannot exceed 50 characters"),
    contact_list: yup
      .array()
      .min(1, "Pick atleast 1")
      .required("Please select the contact")
      .of(
        yup.object().shape({
          value: yup.string().required(),
        })
      ),

    facility_type: yup
      .mixed()
      .transform((value) => {
        if (typeof value === "object" && value !== null && "value" in value) {
          return value;
        }
        return value;
      })
      .required("Please select Facility Type"),
    region_id: yup
      .mixed()
      .transform((value) => {
        if (typeof value === "object" && value !== null && "value" in value) {
          return value;
        }
        return value;
      })
      .required("Please select Region Id"),
    description: yup
      .string()
      .nullable() // Allow null values (empty string)
      .max(200, "Description must be at most 200 characters"),
    address: yup
      .string()
      .nullable() // Allow null values (empty string)
      .max(200, "Address must be at most 200 characters"),
  }),
];

export const facilityTableData = [];

export const createFacilityFormConfig = [
  {
    id: 0,
    name: "",
    heading: "",
    fields: [
      {
        name: "name",
        label: "facilityformlabels.facilityname",
        maxLength: 50,
        type: "input",
        mandatory: "true",
      },
      {
        name: "facility_type",
        label: "facilityformlabels.Facilitytype",
        type: "multi_select",
        is_multi: false,
        mandatory: "true",
      },

      {
        name: "latitude",
        label: "facilityformlabels.latitude",
        type: "input",
      },
      {
        name: "longitude",
        label: "facilityformlabels.longitude",
        type: "input",
      },

      {
        name: "description",
        label: "facilityformlabels.description",
        type: "text_area",
      },
      {
        name: "address",
        label: "facilityformlabels.address",
        type: "text_area",
      },
      {
        name: "contact_list",
        label: "facilityformlabels.contact",
        type: "multi_select",
        mandatory: "true",
        options: [],
        optionLabelKey: "label",
        optionValueKey: "value",
      },
      {
        name: "region_id",
        label: "facilityformlabels.region",
        type: "multi_select",
        mandatory: "true",
        is_multi: false,
      },
    ],
  },
];

export const facilitycolumnsForExport = [
  { label: "Name", key: "name" },
  { label: "Address", key: "address" },
  { label: "District", key: "dist_name" },
  { label: "Block", key: "block_name" },
  { label: "Description", key: "description" },
  { label: "Created On", key: "created_on" },
  { label: "Facility Type", key: "facility_type" },
  { label: "Contact Person", key: "contact_list" },
];
