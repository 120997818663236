import React, { useEffect, useRef } from "react";
import { GridStack } from "gridstack";
import GridItem from "../../../Components/UiComponents/GridItem/GridItem";
import RoleTable from "./RoleTable/RoleTable";
import DMSHeader from "../DMSHeader/DMSHeader";
import { useTranslation } from "react-i18next";

const RoleManagement = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    GridStack.init();
  }, []);

  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: <DMSHeader title={t("role_management_header")} showdownload={true}  fileName="RoleTable" />,
      haveOverflowVisible: true,
      
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height:12,
      id: 2,
      component: <RoleTable/>,
    }
  ];

  return (
    <div className="grid-stack grid-stack-instance-1">
      <GridItem layoutData={layoutData} />
    </div>
  );
};

export default RoleManagement;
