import React, { useContext } from "react";
import Loader from "react-spinner-loader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { toast, ToastContainer } from "react-toastify";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import Table from "../../../../Components/UiComponents/Table/Table";
import {
  Deleteicon,
  Editicon,
  PaperClipIcon,
  Viewicon,
  XCircleblack,
} from "../../../../Components/IconComponents";
import {
  deleteCKById,
  getAnimalShelterListURL,
} from "../../../../Services/EndPoints";
import {
  postDataWithBody,
  putDataWithBody,
  getImageByFacility
} from "../../../../Services/Services";
import { useKeycloak } from "@react-keycloak/web";
import {
  downloadFile,
  filterDate,
  getExportedColumnsData,
} from "../../../../Components/UiComponents/utils";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
import { columnsForExport } from "../../../../JsonData/AnimalShelterConfig";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import TextEllipsis from "../../../../Components/UiComponents/TextEllipsis/TextEllipsis";
import { RoleContext, ThemeContext } from "../../../..";
import { Modal } from "react-bootstrap";
const AnimalShelterTable = () => {
  const { roleName } = useContext(RoleContext);
  const [animalShelterData, setAnimalShelterData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const [rowCount, setRowCount] = useState(0);
  const [searchData, setSearchData] = useState([]);
  const [isShowImageModal, setIsShowImageModal] = useState(false)
  const [imagetype, setimagetype] = useState([])
  const [imageData, setImageData] = useState([])
  const [loader, setLoader] = useState(true);
  const { theme } = useContext(ThemeContext);
  useEffect(() => {
    setTimeout(function () {
      getAnimalShelterList();
    }, 500);
  }, []);

  useEffect(() => {
    sendDataToCentralStore(searchData);
  }, [searchData]);

  const sendDataToCentralStore = (data) => {
    if (data.length > 0) {
      const columns = columnsForExport;
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, data),
        })
      );
    }
  };

  const getAnimalShelterList = async () => {
    // setLoader(true);
    var animalShelterList = await postDataWithBody(
      getAnimalShelterListURL,
      { user_name: getLoggedInuserName() },
      {}
    );
    setRowCount(animalShelterList.length);
    // let  animalShelterList1 = filterDate(animalShelterList, "open_date");
    setAnimalShelterData(animalShelterList);
    //sendDataToCentralStore(animalShelterList);
    setLoader(false);
  };

  const getLoggedInuserName = () => {
    return keycloak.idTokenParsed.email;
  };

   
  const fetchImage = async (facilityId, typeid, opendate) => {
    setLoader(true);
    const response = await getImageByFacility(facilityId, typeid, opendate);
    setimagetype(response);
  const images = response.map(item => `data:image/jpeg;base64,${item.image}`);
  setImageData(images);
  setLoader(false);
    setIsShowImageModal(true);
  };

  const handleClickViewImage = (facilityId, typeid, opendate) => {
    fetchImage(facilityId, typeid, opendate);
  };

  // const handleClickViewImage = () =>{
  //   setIsShowImageModal(true);
  // }

  const hideImageModal = () =>{
    setIsShowImageModal(false);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: t("animalsheltertableheaders.slno"),
        accessor: "sno",
        disableSortBy: false,
        Cell: (props) => <span>{props.row.index + 1}</span>,
      },

      {
        Header: t("animalsheltertableheaders.facility"),
        accessor: "facility_name",
        disableSortBy: false,
        Cell: (props) => {
          return <TextEllipsis text={props.value} maxLength={15} />;
        },
      },
      {
        Header: t("animalsheltertableheaders.district"),
        accessor: "district_name",
        disableSortBy: false,
      },
      {
        Header: t("animalsheltertableheaders.block"),
        accessor: "block_name",
        disableSortBy: false,
      },
      {
        Header: t("animalsheltertableheaders.opendate"),
        accessor: "open_date",
        Cell: (props) => {
          const { open_date } = props.row.original;
          return props.row.original.is_open ?  open_date : open_date;
        },
        disableSortBy: false,
        sortType: (rowA, rowB) => {
          const parseDate = (dateStr) => {
            const [day, month, year] = dateStr.split("/");
            return new Date(year, month - 1, day);
          };

          const dateA =
            rowA.original.is_open && rowA.original.open_date
              ? parseDate(rowA.original.open_date)
              : new Date(-8640000000000000); // Default to a very old date for missing dates
          const dateB =
            rowB.original.is_open && rowB.original.open_date
              ? parseDate(rowB.original.open_date)
              : new Date(-8640000000000000);

          return dateA - dateB;
        },
      },
      {
        Header: t("animalsheltertableheaders.closedate"),
        accessor: "close_date",
        Cell: (props) => {
          const { close_date } = props.row.original;
          return !props.row.original.is_open ?  close_date : "-";
        },
        disableSortBy: false,
        sortType: (rowA, rowB) => {
          const parseDate = (dateStr) => {
            const [day, month, year] = dateStr.split("/");
            return new Date(year, month - 1, day);
          };

          const dateA =
            rowA.original.is_open && rowA.original.close_date
              ? parseDate(rowA.original.close_date)
              : new Date(-8640000000000000); // Default to a very old date for missing dates
          const dateB =
            rowB.original.is_open && rowB.original.close_date
              ? parseDate(rowB.original.close_date)
              : new Date(-8640000000000000);

          return dateA - dateB;
        },
      },
      // {
      //   Header: t("animalsheltertableheaders.operationaldate"),
      //   accessor: "operational_date",
      //   Cell: (props) => {
      //     return props.row.original.operational_date || "NA";
      //   },
      //   disableSortBy: false,
      //   sortType: (rowA, rowB) => {
      //     const parseDate = (dateStr) => {
      //       const [day, month, year] = dateStr.split("/");
      //       return new Date(year, month - 1, day);
      //     };

      //     const dateA = rowA.original.operational_date
      //       ? parseDate(rowA.original.operational_date)
      //       : new Date(-8640000000000000); // Default to a very old date for missing dates
      //     const dateB = rowB.original.operational_date
      //       ? parseDate(rowB.original.operational_date)
      //       : new Date(-8640000000000000);

      //     return dateA - dateB;
      //   },
      // },

      {
        Header: t("animalsheltertableheaders.animalstreated"),

        disableSortBy: false,
        accessor: "total_animals_treated",
        Cell: (props) => {
          let txt = "";
          if (props.value) {
            txt = props.value;
          } else {
            txt = "-";
          }
          return <span className="text-center d-block">{txt}</span>;
        },
        rightAlign: true,
      },
      {
        Header: t("animalsheltertableheaders.animalsfed"),
        accessor: "total_animals_fed",
        disableSortBy: false,
        Cell: (props) => {
          let txt = "";
          if (props.value) {
            txt = props.value;
          } else {
            txt = "-";
          }
          return <span className="text-center d-block">{txt}</span>;
        },
        rightAlign: true,
      },
      {
        Header: t("animalsheltertableheaders.quantityoffodder"),
        accessor: "total_fodder",
        Cell: (props) => {
          let txt = "";
          if (props.value) {
            txt = props.value;
          } else {
            txt = "-";
          }
          return <span className="text-center d-block">{txt}</span>;
        },
        disableSortBy: false,
      },

      {
        Header: t("animalsheltertableheaders.action"),
        accessor: "attachment_path",
        disableSortBy: true,

        Cell: (props) => {
          const [isdelete, setIsdelete] = useState(false);
          const deletePopUp = () => {
            setIsdelete(!isdelete);
          };
          const deleteTableDataById = async (id) => {
            let data = { idVal: id };
            var res = await putDataWithBody(
              deleteCKById + "ck_delete",
              data,
              {}
            );
            if (res[0].status === "success") {
              getAnimalShelterList();
            }
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000, // Close after 2 seconds
            });
            setIsdelete(false);
          };
          let downloadAttachmentURL =
            process.env.REACT_APP_APP_SERVER_ADDRESS +
            "/" +
            process.env.REACT_APP_CORE_CONTEXT_PATH_NAME +
            "/download/download_attachments/?directoryPath=" +
            props.row.original.attachment_path +
            "&fileName=Animalshelter.zip";
          return (
            <div className="text-center d-flex align-items-center">
              <span className="icon__position">
                <div
                onClick={() => {
                  // Reformat the date from '12/11/2022' to '2022-11-12'
                  const originalDate = props.row.original.open_date; // '12/11/2022'
                  const dateParts = originalDate.split('/');  // ['12', '11', '2022']
                  const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;  // '2022-11-12'
                
                  // Now call the handleClickViewImage function with the formatted date
                  handleClickViewImage(
                    props.row.original.facility_id,
                    props.row.original.facility_type_id,
                    `${formattedDate} 00:00:00.0`  // Pass the formatted date with time
                  );
                }}
                >
                  <Viewicon />
                </div>
              </span>
              {roleName === process.env.REACT_APP_SUPER_ADMIN && (
                <span onClick={deletePopUp} className="mx-2">
                  <Deleteicon
                    value={props.value}
                    className="cursor-pointer mr-1"
                  />
                </span>
              )}

              {isdelete && (
                <Confirmpopup
                  show={isdelete}
                  title="Confirm delete"
                  message="Are you sure you want to delete"
                  handleToggle={deletePopUp}
                  row={props.row}
                  name={props.row.original.activity}
                  deleteRow={() => deleteTableDataById(props.row.original.id)}
                  cancel="Cancel"
                  action="Delete"
                />
              )}
            </div>
          );
        },
      },
    ],
    [t, RoleContext]
  );
  return (
    <div>
      <div className="create-user">
        <Loader
         show={loader}
         type="body"
         stack="vertical"
         message="Loading Data"
        />
        <div className="datatable__container">
          {animalShelterData && (
            <Table
              data={animalShelterData}
              setSearchData={setSearchData}
              columns={columns}
              rowCount={rowCount}
              numberOfRows={10}
              showExport={false}
              showFilter={false}
              showReset={false}
              onExpand={false}
              headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
              tableStyle={{
                border: "1px solid  #f0f0f0",
                borderRadius: "12px",
              }}
            />
          )}
        </div>
      </div>
      <Modal className="ckmodal" id={theme} show={isShowImageModal}>
        <Modal.Header>
          <Modal.Title>{t("communitykitchenpopup")}</Modal.Title>
          <XCircleblack
            onClick={hideImageModal}
            width="1.5em"
            height="1.5em"
            className="cursor-pointer"
          />
        </Modal.Header>
        <Modal.Body className="ckmodal-container">
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '80px' }}>
  {imageData.length > 0 ? (
    imageData.map((image, index) => (
      <img 
        key={index}
        src={image}
        alt={`Meal ${index}`}
        style={{
          width: 90,
          height: 90,
          borderRadius: 8,
          objectFit: 'cover'
        }}
      />
    ))
  ) : (
    <p> no images to show</p>
  )}
</div>

        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AnimalShelterTable;
