import React from "react";
import "./SelectComponent.css";

const SelectComponent = ({
  options,
  id,
  onChange,
  value,
  showPlaceHolder,
  classList,
  defaultValue,
  disabled = false,
  style,
}) => {
  return (
    <select
      id={id ? id : ""}
      className={
        classList ? "category__select " + classList : "category__select"
      }
      style={style}
      onChange={onChange}
      value={defaultValue || value}
      disabled={disabled}>
      {showPlaceHolder && (
        <option selected disabled>
          {showPlaceHolder}
        </option>
      )}
      {options?.length > 0 &&
        options.map((item, index) => (
          <option
            data-set={item?.id}
            key={index}
            value={item?.id}
            className="option-text">
            {item.value}
          </option>
        ))}
    </select>
  );
};

export default SelectComponent;
